const common = {
  'es': {
    'forgot_password': 'Recuperar mi contraseña',
    'welcome_back': 'Bienvenido',
    'sign_in_to_continue': 'Identifícate para continuar',
    'forgot_your_password': '¿Olvidaste tu contraseña?',
    'email': 'Correo electrónico',
    'password': 'Contraseña',
    'sign_in': 'Iniciar sesión',
    'required_field': '{{field}} es un campo obligatorio.',
    'recovery_password': 'Recuperar contraseña',
    'send': 'Enviar',
    'enter': 'Entrar',
    'enter_your_email': 'Introduce tu correo electrónico',
    'do_you_want_to_access': '¿Quieres acceder al sistema?',
    'logout': 'Cerrar sesión',
    'copyright': 'Copyright&nbsp;&copy; 2023 - MobileTracking APP',
    'create_user': 'Crear usuario',
    'repeat_password': 'Repetir contraseña',
    'enabled': 'Activado',
    'role': 'Rol',
    'user_role': 'USUARIO',
    'admin_role': 'ADMINISTRADOR',
    'developer_role': 'DESARROLLADOR',
    'ADMIN': 'Administrador',
    'USER': 'Usuario',
    'DEVELOPER': 'Desarrollador',
    'select_role': 'Selecciona rol...',
    'save': 'Guardar',
    'passwords_dont_match': 'Las contraseñas no coinciden.',
    'success_save': 'Registro guardado correctamente.',
    'dashboard': 'Dashboard',
    'sign_out': 'Cerrar sesión',
    'settings': 'Opciones',
    'pages': 'Páginas',
    'appname': 'MobileTracking APP',
    'min_length': 'La longitud de {{field}} tiene que ser mayor o igual que {{length}}.',
    'users': 'Usuarios',
    'actions': 'Acciones',
    'is_active': 'Activado',
    'update_user': 'Actualizar usuario',
    'update_password': 'Actualizar contraseña',
    'not_found': 'El registro no ha sido encontrado.',
    'no_results': 'Sin resultados.',
    'id': 'ID',
    'updated_date': 'Fecha actualización',
    'created_date': 'Fecha creación',
    'yes': 'Sí',
    'no': 'No',
    'orders': 'Pedidos',
    'order_number': 'Núm.Pedido',
    'order_status': 'Estado del Pedido',
    'order_total': 'Total',
    'order_net_total': 'Total neto',
    'currency': 'Moneda',
    'country': 'País',
    'platform': 'Plataforma',
    'ios': 'iOS',
    'android': 'Android',
    'search': 'Buscar',
    'select_status': 'Selecciona estado...',
    'select_platform': 'Selecciona plataforma...',
    'start_date': 'Fecha de inicio',
    'end_date': 'Fecha de fin',
    'success_delete': 'La fila ha sido eliminada correctamente.',
    'delete_title': 'Eliminar',
    'delete_message': '¿Estás seguro de que quieres eliminar la fila?',
    'delete_error': 'Se ha producido un error al intentar eliminar la fila.',
    'login_error': 'El correo electrónico o la contraseña no son correctos',
    'link_sent_successfully': 'El enlace ha sido enviado correctamente a tu correo.',
    'link_sent_error': 'El correo electrónico no existe en la aplicación.',
    'reset_password': 'Restablece tu contraseña',
    'enter_your_new_password': 'Introduce tu nueva contraseña',
    'token_is_not_valid': 'El token no es válido.',
    'password_updated_successfully': 'La contraseña ha sido actualizada correctamente.',
    'password_updated_error': 'Ha ocurrido un error al intentar actualizar la contraseña.',
    'notifications': 'Notificaciones',
    'endpoints': 'Endpoints',
    'notifications_users': 'Notificaciones de usuarios',
    'create_notification': 'Crear notificacion',
    'reset': 'Resetear',
    'update_order': 'Actualizar pedido',
    'url': 'URL',
    'webshop_id': 'Tienda hija',
    'detail': 'Detalle',
    'fcm_token': 'FCM Token',
    'device_uid': 'Device UID',
    'locale_language_code': 'Idioma',
    'disabled': 'Desactivado',
    'is_read': 'Leída',
    'notification_hash': 'HASH',
    'action': 'Acción',  
    'select_action': 'Selecciona acción...',
    'app_blocked': 'APP_BLOCKED',
    'delivered': 'DELIVERED',
    'press': 'PRESS',
    'dismissed': 'DISMISSED',
    'select_read': 'Selecciona leída...',
    'title': 'Título',
    'body': 'Mensaje',
    'required_field_conditional': 'Tienes que completar uno de los campos: {{field1}} o {{field2}} o {{field3}}.',
    'direct': 'Directa',
    'firebase': 'Firebase',
    'select_type': 'Selecciona tipo...',
    'notification_type': 'Tipo de notificación',
    'channel_id': 'Canal (Android only)',
    'open_url': 'URL apertura',
    'success_sent': 'La notificación ha sido enviada correctamente.',
    'success_sent_messages': 'Mensajes correctos: {{successMessages}}',
    'error_sent_messages': 'Mensajes inválidos: {{errorMessages}}',
    'scheduled': 'Programada',
    'time': 'Hora:',
    'check_date': 'Por favor, revisa la fecha.',
    'success_scheduled': 'La notificación ha sido programada correctamente.',
    'api': 'API',
    'api_access': 'Acceso a la API',
    'ip_addresses': 'Direcciones IP',
    'renew_jwt': 'Renovar JWT',
    'create_jwt': 'Crear JWT',
    'create_ip': 'Crear IP',
    'confirm_new_jwt_header': 'Generar un nuevo Json Web Token',
    'confirm_new_jwt_message': '¿Estás seguro de que quieres generar un nuevo Json Web Token?. \nCualquier otro Json Web Token anterior será invalidado.',
    'success_create_jwt': 'Se ha generado el JWT correctamente.',
    'error_create_jwt': 'Ha ocurrido un error al crear el JWT, por favor, inténtalo de nuevo más tarde.',
    'jwt': 'JWT',
    'ip_address': 'Dirección IP',
    'show_jwt': 'Mostrar JWT',
    'write_your_password': 'Introduce de nuevo tu contraseña para poder visualizar el Json Web Token.',
    'close': 'Cerrar',
    'password_is_not_valid': 'La contraseña no es correcta.',
    'create_new_ip_address': 'Crear nueva dirección IP',
    'ip_address_is_not_valid': 'La dirección IP introducida no es válida.',
    'click_here_for_copy': 'Haz clic para copiar el JWT.',
    'success_copy': 'El texto ha sido copiado correctamente.',
    'success_create_ip': 'La dirección IP ha sido creada correctamente.',
    'success_show_jwt': 'La contraseña es correcta, ahora puedes visualizar el JWT.',
    'change_password': 'Cambiar contraseña',
    'select_dates': 'Selecciona fechas:',
    'calculate': 'Calcular',
    'gross_sales': 'Ventas brutas',
    'commission': 'Comisión',
    'highest_sale': 'Venta más alta',
    'avg_sale': 'Venta media',
    'highest_commission': 'Comisión más alta',
    'avg_commission': 'Comisión media',
    'recurrent_orders': 'Pedidos de clientes recurrentes',
    'devices': 'Dispositivos',
    'orders_per_devices': 'Pedidos por dispositivo',
    'scheduled_notifications': 'Notificaciones programadas',
    'active_users': 'Usuarios activos',
    'loading': 'Cargando...',
    'monthly_sales': 'Ventas brutas mensuales',
    'monthly_sales_text': 'Son las ventas brutas mensuales en los últimos doce meses hasta la fecha de inicio seleccionada en el dashboard. Hay que tener en cuenta que si la fecha inicial seleccionada no contempla el mes completo la venta bruta mensual de ese mes podría ser parcial, ya que no acumularía todas las ventas de ese mes.', 
    'sales': 'Ventas',
    'same_period_previous_month': 'Periodo anterior',
    'global_metric': 'Métrica global',
    'loyalty_rate': 'Tasa de recurrencia',
    'gross_sales_text': 'Es el importe bruto vendido en el periodo seleccionado, es la suma de los importes de todos los pedidos realizados.',
    'orders_text': 'Número total de pedidos realizados en el periodo seleccionado. También se muestra la segmentación entre plataformas.',
    'loyalty_rate_text': 'Es el número medio de pedidos por cliente. Se calcula dividiendo el número de pedidos totales entre el número de clientes que han realizado al menos un pedido. Es una métrica global.',
    'active_users_text': 'Es el número total de usuarios que tienen la APP activa en su dispositivo. Esta métrica no se actualiza automáticamente, ya que no hay un mecanismo real para detectar cuando un usuario desinstala la aplicación, para mantenerla actualizada es necesario lanzar una notificación de limpieza, eso detectará los usuarios que han desinstalado la APP. También se muestra la segmentación entre plataformas. Es una métrica global.',
    'highest_sale_text': 'Es la venta más alta realizada en el periodo seleccionado. También se muestra la venta media.',
    'avg_sale_text': 'Es la venta media realizada en el periodo seleccionado. También se muestra la venta más alta en ese periodo.',
    'commission_text': 'Es la comisión obtenida por la aplicación en el periodo seleccionado.',
    'highest_commission_text': 'Es la comisión por pedido más alta obtenida en el periodo seleccionado. También muestra la comision media de cada pedido entrante.',
    'avg_commission_text': 'Es la comisión media obtenida en el periodo seleccionado. También muestra la comision más alta en ese periodo.',
    'scheduled_notifications_text': 'Es el número de notificaciones que están programadas a día de hoy en el sistema. Es una métrica global.',
    'default_dates': 'Fechas por defecto',
    'default_dates_text': 'El valor por defecto del periodo mostrado es el mes en curso.',
    'go_to_homepage': 'Ir a la página de inicio',
    'download_excel': 'Descargar Excel',
    'resume_table_results_one': 'En total hay {{count}} resultado.',
    'resume_table_results_other': 'En total hay {{count}} resultados.',
    'gross_total_eur': 'Total (EUR)',
    'order_total_eur': 'Total (EUR)',
    'net_total_eur': 'Total neto (EUR)',
    'commission_eur': 'Comisión (EUR)',
    'segmentation_per_country': 'Segmentación de pedidos por país',
    'segmentation_per_country_text': 'Segmentación de pedidos por país en el periodo seleccionado.',
    'shops': 'Tiendas',
    'create_shop': 'Crear tienda',
    'update_shop': 'Actualizar tienda',
    'domain': 'Dominio',
    'name': 'Nombre',
    'firebase_file_path': 'Nombre fichero firebase',
    'android_package': 'Paquete Android',
    'ios_package': 'Paquete iOS',
    'multi_country': 'Multi-país',
    'select_shop': 'Selecciona tienda...',
    'warning_message_firebase_files': 'Los fichero de firebase se deben introducir en su directorio en la APP para que funcionen las notificaciones.',
    'user_data': 'Datos de usuario',
    'image_url': 'URL de imagen (jpg, png) - https (Imagen mostrada en la notificación, icono lateral e imagen en grande) (Tamaño máximo 300kB)',
    'image_url_placeholder': 'URL de imagen',
    'keep_form_to_submit': 'Mantener los datos del formulario después de enviar',
    'warning_message_create_notification': 'Es recomendable enviar una notificación de prueba a un dispositivo de TEST antes de enviar la notificación definitiva. De esta forma podemos comprobar su funcionalidad y aspecto.',
    'default_bts': 'General',
    'coupons_bts': 'Cupones',
    'important_bts': 'Importante',
    'firebase_error': 'Error',
    'child_shops': 'Tiendas hijas',
    'field_required': 'El campo es obligatorio.',
    'select_child_shop': 'Selecciona tienda hija...',
    'recurrent': 'Recurrentes',
    'num_orders': 'Número de pedidos',
    'order_net_total_eur': 'Total neto (EUR)',
    'has_error': 'Tiene error',
    'select_error': 'Selecciona error...',
    'app_version': 'Versión de la APP',
    'force_update': 'Forzar actualización',
    'cleaning': 'Limpieza',
    'push_status': 'Notificaciones PUSH',
    'select_push_status': 'Selecciona estado...',
    'unavailable': 'UNAVAILABLE',
    'blocked': 'BLOCKED',
    'denied': 'DENIED',
    'granted': 'GRANTED',
    'limited': 'LIMITED',
    'config_data': 'Datos de configuración',
    'google_play_url': 'URL en Play Store',
    'app_store_url': 'URL en App Store',
    'background_only': 'Enviar sólo si la APP no está en pantalla',
    'native_update': 'Procedimiento de actualización nativo',
    'previous_month': 'Mes anterior',
    'estimate_sales': 'Ventas brutas estimadas',
    'estimate_sales_text': 'Son las ventas brutas estimadas para el mes en curso.',
    'estimate_orders': 'Pedidos estimados',
    'estimate_orders_text': 'Son los pedidos estimados para el mes en curso.',
    'users_with_active_notifications': 'Usuarios con notificaciones activas',
    'users_with_active_notifications_text': 'Son los usuarios que actualmente tienen las notificaciones activas en la aplicación móvil. También se muestra la segmentación entre plataformas. Es una métrica global.',
    'estimate_commission': 'Comisión estimada',
    'estimate_commission_text': 'Es la comisión estimada para el mes en curso.',
    'device_system': 'Versión del sistema',
    'device_model': 'Dispositivo',
    'is_emulator': 'Emulador',
    'select_scheduled': 'Programada...',
    'send_confirm_title': 'Confirmación de envío',
    'send_confirm_message': '¿Estás seguro de que deseas enviar la notificación? <br/><strong>Tipo de Envío:</strong> {{type}} <br/><strong>Device UID:</strong> {{deviceUid}}',
    'scheduled_send_type': 'Programado', 
    'immediate_send_type': 'Inmediato',
    'empty': '- Vacío -',
  },
  'en': {
    'forgot_password': 'Recovery password',
    'welcome_back': 'Welcome',
    'sign_in_to_continue': 'Sign in to your account to continue',
    'forgot_your_password': 'Did you forget your password?',
    'email': 'Email',
    'password': 'Password',
    'sign_in': 'Sign In',
    'required_field': '{{field}} is a required field.',
    'recovery_password': 'Recovery password',
    'send': 'Enviar',
    'enter': 'Sign In',
    'enter_your_email': 'Enter your email',
    'do_you_want_to_access': 'Do you want to access the system?',
    'logout': 'Logout',
    'copyright': 'Copyright&nbsp;&copy; 2023 - MobileTracking APP',
    'create_user': 'Create user',
    'repeat_password': 'Repeat password',
    'enabled': 'Enabled',
    'role': 'Role',
    'user_role': 'USER',
    'admin_role': 'ADMINISTRATOR',
    'developer_role': 'DEVELOPER',
    'ADMIN': 'Administrator',
    'USER': 'User',
    'DEVELOPER': 'Developer',
    'select_role': 'Select role...',
    'save': 'Save',
    'password_dont_match': 'Password don`t match.',
    'success_save': 'Register saved successfull.',
    'dashboard': 'Dashboard',
    'sign_out': 'Sign out',
    'settings': 'Settings',
    'pages': 'Pages',
    'appname': 'MobileTracking APP',
    'min_length': 'The length of {{field}} has to be bigger or equal than {{length}}.',
    'users': 'Users',
    'actions': 'Actions',
    'is_active': 'Enabled',
    'update_user': 'Update user',
    'update_password': 'Update password',
    'not_found': 'Record not found.',
    'no_results': 'No results',
    'id': 'ID',
    'updated_date': 'Updated date',
    'created_date': 'Created date',
    'yes': 'Yes',
    'no': 'No',
    'orders': 'Orders',
    'order_number': 'Order number',
    'order_status': 'Order status',
    'order_total': 'Total',
    'order_net_total': 'Net total',
    'currency': 'Currency',
    'country': 'Country',
    'platform': 'Platform',
    'ios': 'iOS',
    'android': 'Android',
    'search': 'Search',
    'select_status': 'Select status...',
    'select_platform': 'Select platform...',
    'start_date': 'Start date',
    'end_date': 'End date',
    'success_delete': 'The row has been deleted successfully.',
    'delete_title': 'Delete',
    'delete_message': 'Are you sure that you want to delete the row?',
    'delete_error': 'An error has occurred to delete the row.',
    'login_error': 'The email or password is not correct.',
    'link_sent_successfully': 'The link has been sent to your email successfully.',
    'link_sent_error': 'The email doesn´t exist in the application.',
    'reset_password': 'Reset your password',
    'enter_your_new_password': 'Enter your new password',
    'token_is_not_valid': 'Token is not valid.',
    'password_updated_successfully': 'The password has been updated successfully.',
    'password_updated_error': 'An error ocurred to change password.',
    'notifications': 'Notificacions',
    'endpoints': 'Endpoints',
    'notifications_users': 'Notifications users',
    'create_notification': 'Create notification',
    'reset': 'Reset',
    'update_order': 'Update order',
    'url': 'URL',
    'webshop_id': 'Child shop',
    'detail': 'Detalle',
    'fcm_token': 'FCM Token',
    'device_uid': 'Device UID',
    'locale_language_code': 'Language',
    'disabled': 'Disabled',
    'is_read': 'Read',
    'notification_hash': 'HASH',
    'action': 'Action',
    'select_action': 'Select action...',
    'app_blocked': 'APP_BLOCKED',
    'delivered': 'DELIVERED',
    'press': 'PRESS',
    'dismissed': 'DISMISSED',
    'select_read': 'Select read...',
    'title': 'Title',
    'body': 'Message',
    'required_field_conditional': 'Tienes que completar uno de los campos: {{field1}} o {{field2}} o {{field3}}.',
    'direct': 'Direct',
    'firebase': 'Firebase',
    'select_type': 'Select type...',
    'notification_type': 'Notification type',
    'channel_id': 'Canal (Android only)',
    'open_url': 'Open URL',
    'success_sent': 'The notificacion has been sent successfully.',
    'success_sent_messages': 'Success messages: {{successMessages}}',
    'error_sent_messages': 'Error messages: {{errorMessages}}',
    'scheduled': 'Scheduled',
    'time': 'Time:',
    'check_date': 'Por favor, revisa la fecha.',
    'success_scheduled': 'The notification has been scheduled successfully.',
    'api': 'API',
    'api_access': 'API Access',
    'ip_addresses': 'IP Addresses',
    'renew_jwt': 'Renew JWT',
    'create_jwt': 'Create JWT',
    'create_ip': 'Crear IP',
    'confirm_new_jwt_header': 'Generar un nuevo Json Web Token',
    'confirm_new_jwt_message': '¿Estás seguro de que quieres generar un nuevo Json Web Token?. \nCualquier otro Json Web Token anterior será invalidado.',
    'success_create_jwt': 'Se ha generado el JWT correctamente.',
    'error_create_jwt': 'Ha ocurrido un error al crear el JWT, por favor, inténtalo de nuevo más tarde.',
    'jwt': 'JWT',
    'ip_address': 'IP Address',
    'show_jwt': 'Show JWT',
    'write_your_password': 'Introduce de nuevo tu contraseña para poder visualizar el Json Web Token.',
    'close': 'Cerrar',
    'password_is_not_valid': 'The password is not valid.',
    'create_new_ip_address': 'Create new IP address',
    'ip_address_is_not_valid': 'The IP address entered is not valid',
    'click_here_for_copy': 'Click here for copy JWT.',
    'success_copy': 'The text has been copied successfully.',
    'success_create_ip': 'The IP address has been created successfully.',
    'success_show_jwt': 'The password is valid, you can see the JWT now.',
    'change_password': 'Change password',
    'select_dates': 'Select dates:',
    'calculate': 'Calculate',
    'gross_sales': 'Gross sales',
    'commission': 'Commission',
    'highest_sale': 'Highest sale',
    'avg_sale': 'AVG Sale',
    'highest_commission': 'Highest commission',
    'recurrent_orders': 'Orders of recurrent clients',
    'devices': 'Devices',
    'orders_per_devices': 'Orders per device',
    'scheduled_notifications': 'Scheduled notifications',
    'active_users': 'Active users',
    'loading': 'Loading...',
    'monthly_sales': 'Monthly gross sales',
    'monthly_sales_text': 'Son las ventas brutas mensuales en los últimos doce meses hasta la fecha de inicio seleccionada en el dashboard. Hay que tener en cuenta que si la fecha inicial seleccionada no contempla el mes completo la venta bruta mensual de ese mes podría ser parcial, ya que no acumularía todas las ventas de ese mes.', 
    'sales': 'Sales',
    'same_period_previous_month': 'Same previous period',
    'global_metric': 'Global metric',
    'loyalty_rate': 'Recurrent rate',
    'gross_sales_text': 'Es el importe bruto vendido en el periodo seleccionado, es la suma de los importes de todos los pedidos realizados.',
    'orders_text': 'Número total de pedidos realizados en el periodo seleccionado. También se muestra la segmentación entre plataformas.',
    //https://www.hiberus.com/crecemos-contigo/5-indicadores-kpis-imprescindibles-para-mejorar-tu-e-commerce
    'loyalty_rate_text': 'Es el número medio de pedidos por cliente. Se calcula dividiendo el número de pedidos totales entre el número de clientes que han realizado al menos un pedido. Es una métrica global.',
    'active_users_text': 'Es el número total de usuarios que tienen la APP activa en su dispositivo. Esta métrica no se actualiza automáticamente, ya que no hay un mecanismo real para detectar cuando un usuario desinstala la aplicación, para mantenerla actualizada es necesario lanzar una notificación de limpieza, eso detectará los usuarios que han desinstalado la APP. También se muestra la segmentación entre plataformas. Es una métrica global.',
    'highest_sale_text': 'Es la venta más alta realizada en el periodo seleccionado. También se muestra la venta media.',
    'avg_sale_text': 'Es la venta media realizada en el periodo seleccionado. También se muestra la venta más alta en ese periodo.',
    'commission_text': 'Es la comisión obtenida por la aplicación en el periodo seleccionado.',
    'highest_commission_text': 'Es la comisión por pedido más alta obtenida en el periodo seleccionado. También muestra la comision media obtenida de cada pedido entrante.',
    'avg_commission_text': 'Es la comisión media obtenida en el periodo seleccionado. También muestra la comision más alta en ese periodo.',
    'scheduled_notifications_text': 'Es el número de notificaciones que están programadas a día de hoy en el sistema. Es una métrica global.',
    'default_dates': 'Fechas por defecto',
    'default_dates_text': 'El valor por defecto del periodo mostrado es el mes en curso.',
    'go_to_homepage': 'Go to homepage',
    'download_excel': 'Download Excel',
    'gross_total_eur': 'Total (EUR)',
    'order_total_eur': 'Total (EUR)',
    'net_total_eur': 'Net total (EUR)',
    'commission_eur': 'Commission (EUR)',
    'segmentation_per_country': 'Orders segmentation per country',
    'segmentation_per_country_text': 'Orders segmentation per country in selected period.',
    'shops': 'Shops',
    'create_shop': 'Create shop',
    'update_shop': 'Update shop',
    'domain': 'Domain',
    'name': 'Name',
    'firebase_file_path': 'Firebase file name',
    'android_package': 'Android package',
    'ios_package': 'iOS package',
    'multi_country': 'Multi-country',
    'select_shop': 'Select shop...',
    'warning_message_firebase_files': 'Los fichero de firebase se deben introducir en su directorio en la APP para que funcionen las notificaciones.',
    'user_data': 'User data',
    'image_url': 'URL de imagen (jpg, png) - https (Imagen mostrada en la notificación, icono lateral e imagen en grande) (Tamaño máximo 300kB)',
    'image_url_placeholder': 'URL de imagen',
    'keep_form_to_submit': 'Mantener los datos del formulario después de enviar',
    'warning_message_create_notification': 'Es recomendable enviar una notificación de prueba a un dispositivo de TEST antes de enviar la notificación definitiva. De esta forma podemos comprobar su funcionalidad y aspecto.',
    'default_bts': 'Default',
    'coupons_bts': 'Coupons',
    'important_bts': 'Important',
    'firebase_error': 'Error',
    'child_shops': 'Child shops',
    'field_required': 'The field is required.',
    'select_child_shop': 'Select child shop...',
    'recurrent': 'Recurrents',
    'num_orders': 'Total orders',
    'order_net_total_eur': 'Net total (EUR)',
    'has_error': 'Has error',
    'select_error': 'Select error...',
    'app_version': 'APP Version',
    'force_update': 'Force update',
    'cleaning': 'Cleaning',
    'push_status': 'PUSH Notifications',
    'select_push_status': 'Select status...',
    'unavailable': 'UNAVAILABLE',
    'blocked': 'BLOCKED',
    'denied': 'DENIED',
    'granted': 'GRANTED',
    'limited': 'LIMITED',
    'config_data': 'Configuration data',
    'google_play_url': 'Play Store`s URL',
    'app_store_url': 'App Store`s URL',
    'background_only': 'Send only to background APP',
    'native_update': 'Native update',
    'previous_month': 'Previous month',
    'estimate_sales': 'Estimate gross sales',
    'estimate_sales_text': 'Son las ventas brutas estimadas para el mes en curso.',
    'estimate_orders': 'Estimate orders',
    'estimate_orders_text': 'Son los pedidos estimados para el mes en curso.',
    'users_with_active_notifications': 'Users with active notifications',
    'users_with_active_notifications_text': 'Son los usuarios que actualmente tienen las notificaciones activas en la aplicación móvil. También se muestra la segmentación entre plataformas. Es una métrica global.',
    'estimate_commission': 'Estimate commission',
    'estimate_commission_text': 'Es la comisión estimada para el mes en curso.',
    'device_system': 'System version',
    'device_model': 'Device',
    'is_emulator': 'Emulator',
    'select_scheduled': 'Scheduled...',
    'send_confirm_title': 'Confirmación de envío',
    'send_confirm_message': '¿Estás seguro de que deseas enviar la notificación?<br/><strong>Tipo de Envío:</strong> {{type}}<br/><strong>Device UID:</strong> {{deviceUid}}',
    'scheduled_send_type': 'Programado', 
    'immediate_send_type': 'Inmediato',
    'empty': '- Empty -',
  }
};

export default common;