import LoadingIcon from 'components/loading/loading-icon';
import { environment } from 'environments/environment';
import React, { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const UserCreate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        clearErrors,
        setError,
        formState,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });
    const onSubmit = async ({UserCreateForm: data}) => {
        // async request which may result error
        try {
            setLoading(true);
            const response = await fetch(`${environment.apiUrl}/createUser`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            });
            const res = await response.json();

            if (res == false || Array.isArray(res) && res.length == 0) {
                toast.success(t('success_save'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                navigate('/users');
            } else {
                clearErrors();
                //recorremos los errores
                for (const error of res) {
                    //tenemos que rellenar los errores
                    for (const e in error.constraints) {
                        setError(`UserCreateForm.${error.property}`, { type: 'custom', message: error.constraints[e] })      
                    }
                }
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false); 
    }

    let password = watch('UserCreateForm.password');

    return (
        <>
            <div className="container-fluid p-0">

                <div className="mb-3">
                    <h1 className="h3 d-inline align-middle">
                        <strong>{t('create_user')}</strong>
                    </h1>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-6 d-flex">
                        <div className="w-100">
                     
                            <div className="card">

                                <div className="card-body">
                                    <div className="m-sm-3">

                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3" controlId="UserCreateForm.email">
                                                <Form.Label className='required'>{t('email')}</Form.Label>
                                                <Form.Control type="email" 
                                                {...register('UserCreateForm.email', { required: t('required_field', { field: t('email') }) })} 
                                                placeholder={t('email')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.UserCreateForm?.email && <Badge bg="danger" className="mt-2">{errors.UserCreateForm.email?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="UserCreateForm.password">
                                                <Form.Label className='required'>{t('password')}</Form.Label>
                                                <Form.Control type="password" {...register('UserCreateForm.password', { required: t('required_field', { field: t('password') }), 
                                                    minLength: {
                                                        value: 6,
                                                        message: t('min_length', { field: t('password'), length: 6 })
                                                    } })} 
                                                    placeholder={t('password')} 
                                                    onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                    onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                    />
                                                { errors.UserCreateForm?.password && <Badge bg="danger" className="mt-2">{errors.UserCreateForm?.password.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="UserCreateForm.repeat_password">
                                                <Form.Label className='required'>{t('repeat_password')}</Form.Label>
                                                <Form.Control type="password" {...register('UserCreateForm.repeat_password', { required: t('required_field', { field: t('repeat_password') }),
                                                minLength: {
                                                    value: 6,
                                                    message: t('min_length', { field: t('password'), length: 6 })
                                                },
                                                validate: (value) => {                    
                                                    if (password != value) {
                                                        return t('passwords_dont_match');
                                                    }
                                                },
                                                })} 
                                                placeholder={t('repeat_password')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.UserCreateForm?.repeat_password && <Badge bg="danger" className="mt-2">{errors.UserCreateForm?.repeat_password.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="UserCreateForm.role">
                                                <Form.Label className='required'>{t('role')}</Form.Label>
                                                <Form.Select aria-label={t('select_role')} {...register('UserCreateForm.role', { required: t('required_field', { field: t('role') }) })} placeholder={t('role')} >
                                                    <option value="">{t('select_role')}</option>
                                                    <option value="USER">{t('user_role')}</option>
                                                    <option value="ADMIN">{t('admin_role')}</option>
                                                    <option value="DEVELOPER">{t('developer_role')}</option>
                                                </Form.Select>
                                                { errors.UserCreateForm?.role && <Badge bg="danger" className="mt-2">{errors.UserCreateForm?.role.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="UserCreateForm.is_active">
                                                <Form.Label className='required'>{t('enabled')}</Form.Label>
                                                <Form.Check type="switch" {...register('UserCreateForm.is_active', { required: false })} placeholder={t('enabled')} />
                                                { errors.UserCreateForm?.is_active && <Badge bg="danger" className="mt-2">{errors.UserCreateForm?.is_active.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mt-3 d-grid gap-2">
                                                <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-save"></i>&nbsp;{t('save')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                        
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default UserCreate;