import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Trash2, Edit, ToggleRight, Eye } from 'react-feather';
import { Link, useSearchParams } from 'react-router-dom';
import { castValue, empty } from 'helper/helper';
import * as dayjs from 'dayjs';
import { environment } from 'environments/environment';
import ModalConfirm from 'components/modal/modal';
import { useDispatch } from 'react-redux';
import { show } from 'redux/slices/modal/modalConfirmationSlice';
import ModalShowJwt from 'components/modal/modal-show-jwt';
import { showModalJwt } from 'redux/slices/modal/modalShowJwtSlice';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { toast } from 'react-toastify';
import { ShopContext } from 'context/context';

const CTable = ({data, deleteAction, showJwtAction, type, reload: setReload}) => {
    //const itemsRef = useRef([]); //ref={el => itemsRef.current[i] = el}
    const { t } = useTranslation();
    const [sort, setSort] = useState(data?.sort);
    const [sortColumn, setSortColumn] = useState(data?.sort ? {...data?.sort} : null);
    const [items, setItems] = useState([]);
    const [pagination, setPagination] = useState(data?.pagination);
    const [pageSize, setPageSize] = useState(data?.pagination?.pageSize);
    const [numPages, setNumPages] = useState(data?.pagination?.numPages);
    const [active, setActive] = useState(data?.pagination?.active);
    const [tableData, setTableData] = useState(data?.data);
    const [columns, setColumns] = useState(data?.columns);
    const [actions, setActions] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const target = useRef(null);
    const {shop} = useContext(ShopContext);  
    const [childShops, setChildShops] = useState(null);

    const getInitialValues = (async () => {
        try {
            let url = `${environment.apiUrl}/getChildShopsForTable?`;

            let params = new URLSearchParams();

            params.append('domain', shop);

            url += params.toString();

            //nos traemos los datos iniciales
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();

            setChildShops(res);

        } catch(e) {
            console.error(e);
        }
    });

    useEffect(() => {
        getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shop]);

    /**
     * Devuelve un objeto indicando como debe ser la paginacion.
     * 
     * @param {*} currentPage Pagina actual del paginador.
     * @param {*} totalPages Paginas totales existentes.
     * @returns 
     */
    const getPages = (currentPage, range, totalPages) => {
        // comprobamos si el rango es mayor que el numero de paginas
        let nRange = (range > totalPages || range < 0) ? totalPages : range;
        let middle = Math.floor(nRange / 2);
        let num = (currentPage + middle) < totalPages ? (currentPage + middle) : totalPages;
        let from = (num - nRange) >= 0 ? (num - nRange) + 1 : 1;
        let to = (num < nRange) ? nRange : num;

        return { from: from, to: to };
    }

    const handleSort = (event, key) => {
        const columnOrder = sortColumn?.columnIndex == key && sortColumn?.order == 1 ? 0 : 1;
        setSortColumn({ columnIndex: key, order: columnOrder });
        setCurrentPage(1);
        setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: 1, sortColumn: key, sortOrder: columnOrder }, { replace: false });
    };

    const handlePagination = useCallback((event, number) => {
        if (active != number) {
            setActive(number);
            setCurrentPage(number);
            setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: number }, { replace: false });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, setCurrentPage, searchParams]);

    useEffect(() => {
        const page = searchParams.get('page');
        setCurrentPage(page);
    }, [searchParams]);

    const onDelete = (id, url) => {
        dispatch(show({ deleteId: id, title: t('delete_title'), message: t('delete_message'), type: type, url: url }));
    }

    const onShowJwt = () => {
        dispatch(showModalJwt({ title: t('show_jwt'), message: t('write_your_password') }));
    }

    useEffect(() => {
        if (numPages) {   
            let pageItems = [];
            let paginationLimits = getPages(active, environment.maxPagesTables, numPages);
            for (let number = paginationLimits.from; number <= paginationLimits.to; number++) {
                pageItems.push(
                    <Pagination.Item key={number} active={number == active} onClick={(event) => handlePagination(event, number)}>
                        {number}
                    </Pagination.Item>,
                );
            }
            setItems(pageItems);    
        }
    }, [numPages, active, handlePagination]);

    useEffect(() => {
        setTableData(data?.data);
        setSortColumn(data?.sort);
        setPageSize(data?.pagination?.pageSize);
        setNumPages(data?.pagination?.numPages);
        setActive(data?.pagination?.active);
        if (data?.actions) {
            setActions(data?.actions);
        }
    }, [data]);

    const copyText = (val) => {
        try {
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = val;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);

            toast.info(t('success_copy'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <div className='mb-2'>
                <strong>{ t('resume_table_results', { count: data?.total ?? 0 }) }</strong>
            </div>
            <Table striped bordered hover responsive 
                className={
                    classNames({
                        'table-api-responsive': showJwtAction != null,
                    })
                }>
                { 
                    !empty(columns) ?
                        <>
                            <thead>
                                <tr>
                                    {
                                        columns.map((item, i) => (
                                            <th key={i}
                                            className={
                                                classNames({
                                                    'sort-asc': sortColumn?.columnIndex == item && sortColumn?.order == 1,
                                                    'sort-desc': sortColumn?.columnIndex == item && sortColumn?.order == 0,
                                                    'cursor-pointer': sort != false && sortColumn != null,
                                                    'white-space-nowrap': true,
                                                    'text-center': true,
                                                })
                                            } 
                                            onClick={(event) => sortColumn ? handleSort(event, item) : null}>{t(item)}</th>
                                        ))
                                    }
                                    {
                                        (actions != undefined) ? 
                                            <th key={columns.length} 
                                            className={
                                                classNames({
                                                    'white-space-nowrap': true,
                                                    'text-center': true,
                                                })
                                            }>
                                                { t('actions') }
                                            </th> 
                                        :
                                            null
                                    }
                                </tr>
                            </thead>
                        </> 
                    : null
                }
                {    
                    !empty(tableData) ?
                        <>
                            <tbody>                    
                                {
                                    tableData.map((item, i) => (
                                        <tr key={i}>
                                            {
                                                columns.map((key, j) => {
                                                    let value = item[key] === true ? t('yes') : item[key] === false ? t('no') : item[key]; 
                                                    
                                                    if (['scheduled', 'updated_date', 'created_date'].includes(key) && !empty(value)) {
                                                        value = dayjs(value).format('DD-MM-YYYY HH:mm:ss');
                                                    }

                                                    if (empty(value)) {
                                                        value = '-';
                                                    }
                                                    
                                                    return (<td key={j} 
                                                        className={
                                                            classNames({
                                                                'white-space-nowrap': true,
                                                                'text-center': true,
                                                            })
                                                        }>
                                                            {
                                                                key === 'jwt' && data?.hiddenJwt !== true
                                                                ?   
                                                                    <>
                                                                        <OverlayTrigger
                                                                                placement="right"
                                                                                delay={{ show: 250, hide: 400 }}
                                                                                overlay={(props) =>
                                                                                    <Tooltip id="button-tooltip" {...props}>
                                                                                        { t('click_here_for_copy') }
                                                                                    </Tooltip>
                                                                                }
                                                                        >
                                                                            <span 
                                                                                onClick={(e) => copyText(item?.jwt)}
                                                                                className={
                                                                                    classNames({
                                                                                        'blur-text': false,
                                                                                        'break-word': true,
                                                                                        'cursor-pointer': true,
                                                                                        'user-select-text': true,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {value}
                                                                            </span>

                                                                        </OverlayTrigger>
                                                                    </>
                                                                :
                                                                    (key === 'webshop_id') 
                                                                    
                                                                    ?
                                                                        childShops?.[value] ? childShops?.[value] : value ? value : '-'
                                                                    :
                                                                        <span 
                                                                            className={
                                                                                classNames({
                                                                                    'blur-text': key === 'jwt' && data?.hiddenJwt !== false,
                                                                                    'user-select-none': key === 'jwt' && data?.hiddenJwt !== false,
                                                                                    'break-word': ['jwt', 'detail'].includes(key),
                                                                                })
                                                                            }
                                                                        >
                                                                            { !['order_number', 'device_uid', 'webshop_id', 'num_orders'].includes(key) ? castValue(value, 'float') : value }
                                                                        </span>
                                                            }
                                                            
                                                            </td>);
                                                })
                                            }
                                            {
                                                //si es el ultimo elemento...
                                                actions != null ? 
                                                    <td key={'actions-1'} 
                                                    className={
                                                        classNames({
                                                            'white-space-nowrap': true,
                                                            'text-center': true,
                                                        })
                                                    } >
                                                        {
                                                            (Object.keys(actions)).map((k, index) => {
                                                                if (k == 'update') {
                                                                    let action = `${actions[k]}`.replace('{id}', item['id']);
                                                                    return (
                                                                        <Link key={index} to={action}>
                                                                            <Edit className="align-middle"/>
                                                                            <span>&nbsp;</span>
                                                                        </Link>
                                                                    );
                                                                } else if (k == 'update_webshop') {
                                                                    let action = `${actions[k]}`.replace('{domain}', item['domain']);
                                                                    return (
                                                                        <Link key={index} to={action}>
                                                                            <Edit className="align-middle"/>
                                                                            <span>&nbsp;</span>
                                                                        </Link>
                                                                    );
                                                                } else if (k == 'notification_endpoint') {
                                                                    let action = `${actions[k]}`.replace('{id}', item['id']);
                                                                    return (
                                                                        <Link key={index} to={action}>
                                                                            <ToggleRight className="align-middle"/>
                                                                            <span>&nbsp;</span>
                                                                        </Link>
                                                                    );
                                                                }  else if (k == 'show_jwt') {
                                                                    let action = `${actions[k]}`.replace('{id}', item['id']);
                                                                    return (<span style={{'color': 'rgb(59,125,221)'}} key={index} 
                                                                        className={
                                                                            classNames({
                                                                                'cursor-pointer': true,
                                                                                'disabled': data?.hiddenJwt === false,
                                                                            })
                                                                        } onClick={(event) => { 
                                                                            if (data?.hiddenJwt != false) {
                                                                                onShowJwt();
                                                                            } 
                                                                        }}>
                                                                        <Eye className="align-middle"/>
                                                                        <span>&nbsp;</span>
                                                                    </span>);
                                                                } else if (k == 'delete_notification') {
                                                                    return item['scheduled'] != null ? (<span style={{'color': 'rgb(59,125,221)'}} key={index} className={'cursor-pointer'} onClick={(event) => onDelete(item['id'], deleteAction)}>
                                                                        <Trash2 className="align-middle"/>
                                                                        <span>&nbsp;</span>
                                                                    </span>) : null;
                                                                } else if (k == 'delete_order') {
                                                                    return (<span style={{'color': 'rgb(59,125,221)'}} key={index} className={'cursor-pointer'} onClick={(event) => onDelete(item['domain'], deleteAction)}>
                                                                        <Trash2 className="align-middle"/>
                                                                        <span>&nbsp;</span>
                                                                    </span>);
                                                                } else if (k == 'delete') {
                                                                    return (<span style={{'color': 'rgb(59,125,221)'}} key={index} className={'cursor-pointer'} onClick={(event) => onDelete(item['id'], deleteAction)}>
                                                                        <Trash2 className="align-middle"/>
                                                                        <span>&nbsp;</span>
                                                                    </span>);
                                                                } 
                                                            })
                                                        }
                                                    </td>
                                                
                                                : ''  
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </>
                    : 
                    <tbody>
                        <tr>
                            {
                                !empty(columns) ? 
                                <td colSpan={columns.length + (!empty(actions) ? (Object.keys(actions)).length : 0)}>{t('no_results')}</td>
                                : 
                                null
                            }
                        </tr>
                    </tbody>
                }
            </Table>
            {
                !empty(deleteAction) ? <ModalConfirm reload={setReload}></ModalConfirm> : null
            }
            {
                !empty(showJwtAction) ? <ModalShowJwt url={showJwtAction}></ModalShowJwt> : null
            }
            {
                !empty(tableData) && pagination !== false ?
                    <Pagination size="sm" className='justify-content-center'>
                        <Pagination.First onClick={(event) => handlePagination(event, 1)}/>
                        {items}
                        <Pagination.Last onClick={(event) => handlePagination(event, numPages)}/>
                    </Pagination> 
                :
                null
            }
        </>

    )
};

export default CTable;