const integerIntl = new Intl.NumberFormat('es-ES', { style: 'decimal', minimumFractionDigits: 0, useGrouping: true });
const numberIntl = new Intl.NumberFormat('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });

/**
* Cuidado con esto: https://stackoverflow.com/questions/6667291/javascript-error-cannot-convert-object-to-primitive-value
*
* @param field
* @returns
*/
const empty = (field) => {
    if (
    (typeof field === 'number' && isNaN(field)) ||
    (typeof field !== 'boolean' &&
        (field === null ||
        field === 'null' ||
        field === undefined ||
        field === 'undefined' ||
        (typeof field === 'string' && field.trim() === '') ||
        (Array.isArray(field) && field.length === 0) ||
        (typeof field === 'object' && Object.keys(field).length === 0)))
    ) {
        return true;
    }

    return false;
}

const castValue = (value, type = null) => {
    try {
        //vamos a detectar si es un numero
        if (!isNaN(value)) {
            if (type !== null) {
                return type == 'int' ? integerIntl.format(value) : numberIntl.format(value);
            } else {
                //integer
                if (Number(value) % 1 === 0) {
                    return integerIntl.format(value);
                }
                //float
                if (Number(value) % 1 !== 0) {
                    return numberIntl.format(value);
                }
            }
        }

    } catch (e) {
        console.error(e);
    }
    
    return value;
}

const toJSON = (value) => {
    let v = null;
    try {
        v = JSON.parse(value);
    } catch(e) {
        v = null;
    }

    return v;
}

export {empty, castValue, toJSON};