import { JwtTokenContext } from 'context/context';
import { empty } from 'helper/helper';
import { useLocalStorage } from 'hooks/useLocalStorage';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'intl-pluralrules';

function App() {
  const [jwtToken, setJwtToken, rol, email] = useLocalStorage('jwtToken', null);
  const navigate = useNavigate();
  const { fetch: origFetch } = window;
  //nos aseguramos de que se queda con la version original de fetch
  const originalFetch = useCallback((...args) => {
    return origFetch(...args);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const [firstRequest, setFirstRequest] = useState(true);
  const [count, setCount] = useState(0);

  //se encarga de detectar si es la primera request o sucesivas
  useEffect(() => {
    //window.history.replaceState(null, '', location.pathname + location.search);
    //window.history.pushState(null, '', location.pathname + location.search);
    if (count == 0) {
      setFirstRequest(true);
    } else {
      setFirstRequest(false);
    }
    setCount(count + 1); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    window.fetch = async (...args) => {
      let [resource, config ] = args;

      // request interceptor starts
      //console.log(resource)
      // request interceptor ends 
      
      let options = config;
      
      if (!empty(jwtToken)) {
        const headers = config.headers;
        const requestHeaders = new Headers();
        requestHeaders.set('Authorization', 'Bearer ' + jwtToken);
        //copiamos todos los headers en nuestro nuevo objeto
        for (const key in headers) {
          requestHeaders.set(key, headers[key]);
        }
        options = { ...config, headers: requestHeaders };
      }

      const response = await originalFetch(resource, options);
      
      // response interceptor starts
      if ([401, 403].includes(response.status)) {
        navigate('/login');
      }
      // response interceptor ends
      return response;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken, originalFetch]);

  // const dispatch = useDispatch();
  // const getJwtTokenLocalStorage = () => {
  //   let jwtToken = null;
  //   try {
  //     jwtToken= window.localStorage.getItem('jwtToken');
  //     dispatch(login(jwtToken));
  //   } catch(error) {
  //     window.localStorage.removeItem('jwtToken');
  //     dispatch(login(null));
  //   }
  //   return jwtToken;
  // };

  //getJwtTokenLocalStorage();

  return (
    <>
      <JwtTokenContext.Provider value={[jwtToken, setJwtToken, rol, email, firstRequest]}>
          <Outlet />
          <ToastContainer/>
      </JwtTokenContext.Provider>
    </>
  );
}

export default App;