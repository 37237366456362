import LoadingIcon from 'components/loading/loading-icon';
import { environment } from 'environments/environment';
import { empty, toJSON } from 'helper/helper';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import VanillaJSONEditor from 'components/jsoneditor/jsoneditor';

const WebshopUpdate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        clearErrors,
        setError,
        formState,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });

    const [content, setContent] = useState({
        json: {},
        text: undefined
    });
    const [editor, setEditor] = useState(null);

    const [contentConfig, setContentConfig] = useState({
        json: {},
        text: undefined
    });
    const [editorConfig, setEditorConfig] = useState(null);

    const getInitialValues = (async () => {
        try {
            //nos traemos los datos iniciales
            const response = await fetch(`${environment.apiUrl}/getWebshop/${id}`, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
            
            if (!empty(res) && empty(res?.error)) {
                setValue('WebshopUpdateForm.domain', res?.domain);
                setValue('WebshopUpdateForm.name', res?.name);
                setValue('WebshopUpdateForm.commission', res?.commission);
                //setValue('WebshopUpdateForm.firebase_file_path', res?.firebase_file_path);
                setValue('WebshopUpdateForm.android_package', res?.android_package);
                // setValue('WebshopUpdateForm.google_play_url', res?.google_play_url);
                setValue('WebshopUpdateForm.ios_package', res?.ios_package);
                // setValue('WebshopUpdateForm.app_store_url', res?.app_store_url);
                setValue('WebshopUpdateForm.app_version', res?.app_version);
                setValue('WebshopUpdateForm.force_update', res?.force_update); 
                setValue('WebshopUpdateForm.native_update', res?.native_update); 
                setValue('WebshopUpdateForm.multi_country', res?.multi_country);
                setValue('WebshopUpdateForm.child_shops', res?.child_shops);
                setValue('WebshopUpdateForm.config_data', res?.config_data);
                setValue('WebshopUpdateForm.is_active', res?.is_active);   
                
                setContent({
                    text: res?.child_shops
                });

                setContentConfig({
                    text: res?.config_data
                });

            } else {
                toast.error(t('not_found'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                navigate('/shops');
            }
        
        } catch (e) {
            console.error(e);
        }
    });

    useEffect(() => {
        getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSubmit = async ({WebshopUpdateForm: data}) => {
        // async request which may result error
        try {
            setLoading(true);
            const response = await fetch(`${environment.apiUrl}/updateWebshop`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            });
            const res = await response.json();

            if (res == false || Array.isArray(res) && res.length == 0) {
                toast.success(t('success_save'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                //await getInitialValues();

                navigate('/shops');

            } else {
                clearErrors();
                //recorremos los errores
                for (const error of res) {
                    //tenemos que rellenar los errores
                    for (const e in error.constraints) {
                        setError(`WebshopUpdateForm.${error.property}`, { type: 'custom', message: error.constraints[e] })      
                    }
                }
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false); 
    }

    useEffect(() => {
        setValue('WebshopUpdateForm.child_shops', content.text);
        if (editor) {
            let error = editor.validate();
            if (error) {
                setError('WebshopUpdateForm.child_shops', { type: 'custom', message: error?.parseError?.message } );
            } else if (!empty(content.text) && !empty(toJSON(content.text))) {
                setError('WebshopUpdateForm.child_shops', null);
            } else {
                setError('WebshopUpdateForm.child_shops', { type: 'custom', message: t('required_field', { field: t('child_shops') }) });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, setError])

    useEffect(() => {
        setValue('WebshopUpdateForm.config_data', contentConfig.text);
        if (editor) {
            let error = editorConfig.validate();
            if (error) {
                setError('WebshopUpdateForm.config_data', { type: 'custom', message: error?.parseError?.message } );
            } else if (!empty(contentConfig.text) && !empty(toJSON(contentConfig.text))) {
                setError('WebshopUpdateForm.config_data', null);
            } else {
                setError('WebshopUpdateForm.config_data', { type: 'custom', message: t('required_field', { field: t('config_data') }) });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentConfig, setError])

    return (
        <>
            <div className="container-fluid p-0">

                <div className="mb-3">
                    <h1 className="h3 d-inline align-middle">
                        <strong>{t('update_shop')}</strong>
                    </h1>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-6 d-flex">
                        <div className="w-100">
                     
                            <div className="card">

                                <div className="card-body">
                                    <div className="m-sm-3">

                                        <Alert key={'warning'} variant={'warning'}>
                                            <i className="bi bi-exclamation-circle-fill"></i> { t('warning_message_firebase_files') }
                                        </Alert>

                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.domain">
                                                <Form.Label className='required'>{t('domain')}</Form.Label>
                                                <Form.Control type="text" disabled={true}
                                                {...register('WebshopUpdateForm.domain', { required: t('required_field', { field: t('domain') }) })} 
                                                placeholder={t('domain')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.domain && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.domain?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.name">
                                                <Form.Label className='required'>{t('name')}</Form.Label>
                                                <Form.Control type="text" 
                                                {...register('WebshopUpdateForm.name', { required: t('required_field', { field: t('name') }) })} 
                                                placeholder={t('name')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.name && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.name?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.child_shops">
                                                <Form.Label className='required'>{t('child_shops')}</Form.Label>
                                                <div className="my-editor">
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'WebshopUpdateForm.child_shops'}
                                                        rules={{
                                                            validate: {
                                                                required: (value) => {
                                                                    if (empty(value) || empty(toJSON(value))) {
                                                                        return t('required_field', { field: t('child_shops') });
                                                                    }
                                                                },
                                                                custom: (value) => {
                                                                    if (editor) {
                                                                        let error = editor.validate();
                                                                        if (error) {
                                                                            return error?.parseError?.message;
                                                                        } 
                                                                    }
                                                                }
                                                            },
                                                        }}
                                                        render={({ field }) => {
                                                            {/* https://github.com/josdejong/svelte-jsoneditor#properties */}
                                                            return (<VanillaJSONEditor
                                                                value={field.value}
                                                                setEditor={setEditor}
                                                                mode={'text'}
                                                                mainMenuBar={true}
                                                                statusBar={false}
                                                                navigationBar={false}
                                                                askToFormat={false}
                                                                content={content}
                                                                //readOnly={readOnly}
                                                                //setValue('WebshopUpdateForm.child_shops', e.text, { shouldDirty: true });
                                                                onChange={(updatedContent, previousContent, { contentErrors, patchResult }) => { 
                                                                    setContent(updatedContent);
                                                                    //lo hacemos asi para desbloquear el boton de guardar 
                                                                    if (previousContent.text) {
                                                                        setValue('WebshopUpdateForm.child_shops', updatedContent.text, { shouldDirty: true });
                                                                    }
                                                                    
                                                                }}
                                                            />);
                                                        }}
                                                    />
                                                </div>
                                                { errors.WebshopUpdateForm?.child_shops && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.child_shops?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.config_data">
                                                <Form.Label className='required'>{t('config_data')}</Form.Label>
                                                <div className="my-editor">
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'WebshopUpdateForm.config_data'}
                                                        rules={{
                                                            validate: {
                                                                required: (value) => {
                                                                    if (empty(value) || empty(toJSON(value))) {
                                                                        return t('required_field', { field: t('config_data') });
                                                                    }
                                                                },
                                                                custom: (value) => {
                                                                    if (editorConfig) {
                                                                        let error = editorConfig.validate();
                                                                        if (error) {
                                                                            return error?.parseError?.message;
                                                                        } 
                                                                    }
                                                                }
                                                            },
                                                        }}
                                                        render={({ field }) => {
                                                            {/* https://github.com/josdejong/svelte-jsoneditor#properties */}
                                                            return (<VanillaJSONEditor
                                                                value={field.value}
                                                                setEditor={setEditorConfig}
                                                                mode={'text'}
                                                                mainMenuBar={true}
                                                                statusBar={false}
                                                                navigationBar={false}
                                                                askToFormat={false}
                                                                content={contentConfig}
                                                                //readOnly={readOnly}
                                                                //setValue('WebshopUpdateForm.config_data', e.text, { shouldDirty: true });
                                                                onChange={(updatedContent, previousContent, { contentErrors, patchResult }) => { 
                                                                    setContentConfig(updatedContent);
                                                                    //lo hacemos asi para desbloquear el boton de guardar 
                                                                    if (previousContent.text) {
                                                                        setValue('WebshopUpdateForm.config_data', updatedContent.text, { shouldDirty: true });
                                                                    }
                                                                    
                                                                }}
                                                            />);
                                                        }}
                                                    />
                                                </div>
                                                { errors.WebshopUpdateForm?.config_data && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.config_data?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.commission">
                                                <Form.Label className='required'>{t('commission')}</Form.Label>
                                                <Form.Control type="number" step="any" min="0" max="1"
                                                {...register('WebshopUpdateForm.commission', { required: t('required_field', { field: t('commission') }) })} 
                                                placeholder={t('commission')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => { 
                                                    // const key = e.key;
                                                    // if (empty(key.match(/^\d+$/)) && ((e.code != 'Backspace' && e.keyCode != 8) && (e.code != 'Tab' && e.keyCode != 9))) {
                                                    //     e.preventDefault();
                                                    // }
                                                    return e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null
                                                }}
                                                />
                                                { errors.WebshopUpdateForm?.commission && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.commission?.message}</Badge> }
                                            </Form.Group>

                                            {/* <Form.Group className="mb-3" controlId="WebshopUpdateForm.firebase_file_path">
                                                <Form.Label className='required'>{t('firebase_file_path')}</Form.Label>
                                                <Form.Control type="text" 
                                                {...register('WebshopUpdateForm.firebase_file_path', { required: t('required_field', { field: t('firebase_file_path') }) })} 
                                                placeholder={t('firebase_file_path')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.firebase_file_path && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.firebase_file_path?.message}</Badge> }
                                            </Form.Group> */}

                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.android_package">
                                                <Form.Label className='required'>{t('android_package')}</Form.Label>
                                                <Form.Control type="text" 
                                                {...register('WebshopUpdateForm.android_package', { required: t('required_field', { field: t('android_package') }) })} 
                                                placeholder={t('android_package')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.android_package && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.android_package?.message}</Badge> }
                                            </Form.Group>

                                            {/* <Form.Group className="mb-3" controlId="WebshopUpdateForm.google_play_url">
                                                <Form.Label className=''>{t('google_play_url')}</Form.Label>
                                                <Form.Control type="text" 
                                                {...register('WebshopUpdateForm.google_play_url', { })} 
                                                placeholder={t('google_play_url')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.google_play_url && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.google_play_url?.message}</Badge> }
                                            </Form.Group> */}

                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.ios_package">
                                                <Form.Label className='required'>{t('ios_package')}</Form.Label>
                                                <Form.Control type="text" 
                                                {...register('WebshopUpdateForm.ios_package', { required: t('required_field', { field: t('ios_package') }) })} 
                                                placeholder={t('ios_package')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.ios_package && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.ios_package?.message}</Badge> }
                                            </Form.Group>

                                            {/* <Form.Group className="mb-3" controlId="WebshopUpdateForm.app_store_url">
                                                <Form.Label className=''>{t('app_store_url')}</Form.Label>
                                                <Form.Control type="text" 
                                                {...register('WebshopUpdateForm.app_store_url', { })} 
                                                placeholder={t('app_store_url')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.app_store_url && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.app_store_url?.message}</Badge> }
                                            </Form.Group> */}

                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.app_version">
                                                <Form.Label className='required'>{t('app_version')}</Form.Label>
                                                <Form.Control type="text" 
                                                {...register('WebshopUpdateForm.app_version', { required: t('required_field', { field: t('app_version') }) })} 
                                                placeholder={t('app_version')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.WebshopUpdateForm?.app_version && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm.app_version?.message}</Badge> }
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.force_update">
                                                <Form.Label className='required'>{t('force_update')}</Form.Label>
                                                <Form.Check type="switch" {...register('WebshopUpdateForm.force_update', { required: false })} placeholder={t('force_update')} />
                                                { errors.WebshopUpdateForm?.force_update && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm?.force_update.message}</Badge> }
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.native_update">
                                                <Form.Label className='required'>{t('native_update')}</Form.Label>
                                                <Form.Check type="switch" {...register('WebshopUpdateForm.native_update', { required: false })} placeholder={t('native_update')} />
                                                { errors.WebshopUpdateForm?.native_update && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm?.native_update.message}</Badge> }
                                            </Form.Group>
                                            
                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.multi_country">
                                                <Form.Label className='required'>{t('multi_country')}</Form.Label>
                                                <Form.Check type="switch" {...register('WebshopUpdateForm.multi_country', { required: false })} placeholder={t('multi_country')} />
                                                { errors.WebshopUpdateForm?.multi_country && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm?.multi_country.message}</Badge> }
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="WebshopUpdateForm.is_active">
                                                <Form.Label className='required'>{t('enabled')}</Form.Label>
                                                <Form.Check type="switch" {...register('WebshopUpdateForm.is_active', { required: false })} placeholder={t('enabled')} />
                                                { errors.WebshopUpdateForm?.is_active && <Badge bg="danger" className="mt-2">{errors.WebshopUpdateForm?.is_active.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mt-3 d-grid gap-2">
                                                <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-save"></i>&nbsp;{t('save')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                        
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default WebshopUpdate;