import LoadingIcon from 'components/loading/loading-icon';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

const UserUpdate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        clearErrors,
        setError,
        formState,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });

    const {
        register: registerPassword,
        handleSubmit: handleSubmitPassword,
        watch: watchPassword,
        getValues: getValuesPassword,
        clearErrors: clearErrorsPassword,
        reset: resetPassword,
        setError: setErrorPassword,
        formState: formStatePassword,
        formState: { errors: errorsPassword, isDirty: isDirtyPassword, dirtyFields: dirtyFieldsPassword },
    } = useForm();

    const getInitialValues = (async () => {
        try {
            //nos traemos los datos iniciales
            const response = await fetch(`${environment.apiUrl}/getUser/${id}`, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();

            if (!empty(res) && empty(res?.error)) {
                setValue('UserUpdateForm.email', res?.email);
                setValue('UserUpdateForm.role', res?.role);
                setValue('UserUpdateForm.is_active', res?.is_active);   
            } else {
                toast.error(t('not_found'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                navigate('/users');
            }
        
        } catch (e) {
            console.error(e);
        }
    });

    useEffect(() => {
        getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSubmit = async ({UserUpdateForm: data}) => {
        // async request which may result error
        try {
            setLoading(true);
            const response = await fetch(`${environment.apiUrl}/updateUser`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...data, id: id }), // body data type must match "Content-Type" header
            });

            const res = await response.json();

            if (res == false || Array.isArray(res) && res.length == 0) {
                toast.success(t('success_save'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                //await getInitialValues();

                navigate('/users');

            } else {
                clearErrors();
                //recorremos los errores
                for (const error of res) {
                    //tenemos que rellenar los errores
                    for (const e in error.constraints) {
                        setError(`UserUpdateForm.${error.property}`, { type: 'custom', message: error.constraints[e] })      
                    }
                }
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false); 
    }

    const onSubmitPassword = async ({UserUpdatePasswordForm: data}) => {
        // async request which may result error
        try {
            setLoadingPassword(true);
            const response = await fetch(`${environment.apiUrl}/updatePassword`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...data, id: id }), // body data type must match "Content-Type" header
            });
            
            const res = await response.json();

            if (res == false || Array.isArray(res) && res.length == 0) {
                toast.success(t('success_save'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                resetPassword();
                navigate('/users');
            } else {
                clearErrorsPassword();
                //recorremos los errores
                for (const error of res) {
                    //tenemos que rellenar los errores
                    for (const e in error.constraints) {
                        setErrorPassword(`UserUpdatePasswordForm.${error.property}`, { type: 'custom', message: error.constraints[e] })      
                    }
                }
            }

        } catch (e) {
            // handle your error
        }
        setLoadingPassword(false); 
    }

    let password = watchPassword('UserUpdatePasswordForm.password');

    return (
        <>
            <div className="container-fluid p-0">

                <div className="row">
                    <div className="col-12 col-xl-6">
                        <div className="mb-3">
                            <h1 className="h3 d-inline align-middle">
                                <strong>{t('update_user')}</strong>
                            </h1>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="m-sm-3">

                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="mb-3" controlId="UserUpdateForm.email">
                                            <Form.Label className='required'>{t('email')}</Form.Label>
                                            <Form.Control type="email" 
                                            {...register('UserUpdateForm.email', { required: t('required_field', { field: t('email') }) })} 
                                            disabled={true} 
                                            placeholder={t('email')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.UserUpdateForm?.email && <Badge bg="danger" className="mt-2">{errors.UserUpdateForm.email?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="UserUpdateForm.role">
                                            <Form.Label className='required'>{t('role')}</Form.Label>
                                            <Form.Select aria-label={t('select_role')} {...register('UserUpdateForm.role', { required: t('required_field', { field: t('role') }) })} placeholder={t('role')} >
                                                <option value="">{t('select_role')}</option>
                                                <option value="USER">{t('user_role')}</option>
                                                <option value="ADMIN">{t('admin_role')}</option>
                                                <option value="DEVELOPER">{t('developer_role')}</option>
                                            </Form.Select>
                                            { errors.UserUpdateForm?.role && <Badge bg="danger" className="mt-2">{errors.UserUpdateForm?.role.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="UserUpdateForm.is_active">
                                            <Form.Label className='required'>{t('enabled')}</Form.Label>
                                            <Form.Check type="switch" {...register('UserUpdateForm.is_active', { required: false })} placeholder={t('enabled')} />
                                            { errors.UserUpdateForm?.is_active && <Badge bg="danger" className="mt-2">{errors.UserUpdateForm?.is_active.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mt-3 d-grid gap-2">
                                            <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-save"></i>&nbsp;{t('save')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-xl-6">
                        <div className="mb-3">
                            <h1 className="h3 d-inline align-middle">
                                <strong>{t('update_password')}</strong>
                            </h1>
                        </div>
                        <div className="card">

                            <div className="card-body">
                                <div className="m-sm-3">

                                    <Form onSubmit={handleSubmitPassword(onSubmitPassword)}>
                                        <Form.Group className="mb-3" controlId="UserUpdatePasswordForm.password">
                                            <Form.Label className='required'>{t('password')}</Form.Label>
                                            <Form.Control type="password" {...registerPassword('UserUpdatePasswordForm.password', { required: t('required_field', { field: t('password') }), 
                                                minLength: {
                                                    value: 6,
                                                    message: t('min_length', { field: t('password'), length: 6 })
                                                } })} placeholder={t('password')} />
                                            { errorsPassword.UserUpdatePasswordForm?.password && <Badge bg="danger" className="mt-2">{errorsPassword.UserUpdatePasswordForm?.password.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="UserUpdatePasswordForm.repeat_password">
                                            <Form.Label className='required'>{t('repeat_password')}</Form.Label>
                                            <Form.Control type="password" {...registerPassword('UserUpdatePasswordForm.repeat_password', { required: t('required_field', { field: t('repeat_password') }),
                                            minLength: {
                                                value: 6,
                                                message: t('min_length', { field: t('password'), length: 6 })
                                            },
                                            validate: (value) => {                    
                                                if (password != value) {
                                                    return t('passwords_dont_match');
                                                }
                                            },
                                            })} placeholder={t('repeat_password')} />
                                            { errorsPassword.UserUpdatePasswordForm?.repeat_password && <Badge bg="danger" className="mt-2">{errorsPassword.UserUpdatePasswordForm?.repeat_password.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mt-3 d-grid gap-2">
                                            <Button className="btn-lg" type="submit" variant="primary" disabled={loadingPassword || !isDirtyPassword}><i className="bi bi-save"></i>&nbsp;{t('save')}{ loadingPassword && <LoadingIcon name={'three-dots'}/> }</Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default UserUpdate;