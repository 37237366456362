import React from 'react';
import {
  createBrowserRouter,
} from "react-router-dom";
import ErrorPage from 'pages/error/error-page';
import Login from 'pages/login/login';
import Contact from './contact';
import App from 'App';
import RecoveryPassword from 'pages/recovery/recovery-password';
import Protected from 'components/protected/protected';
import Dashboard from 'pages/dashboard/dashboard';
import Layout from 'components/layout/layout';
import User from 'pages/user/user';
import UserCreate from 'pages/usercreate/user-create';
import UserUpdate from 'pages/userupdate/user-update';
import Order from 'pages/order/order';
import ResetPassword from 'pages/resetpassword/reset-password';
import Notification from 'pages/notification/notification';
import Endpoint from 'pages/endpoint/endpoint';
import NotificationCreate from 'pages/notificationcreate/notification-create';
import OrderUpdate from 'pages/orderupdate/order-update';
import NotificationEndpoint from 'pages/notificationendpoint/notification-endpoint';
import Api from 'pages/api/api';
import UserPassword from 'pages/userpassword/user-password';
import Webshop from 'pages/webshop/webshop';
import WebshopCreate from 'pages/webshopcreate/webshop-create';
import WebshopUpdate from 'pages/webshopupdate/webshop-update';
import Recurrent from 'pages/recurrent/recurrent';

const routes = createBrowserRouter([
  {
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      // {
      //   path: "contacts/:contactId",
      //   element:(
      //     <Protected requiresLogin={true}>
      //       <Contact/>
      //     </Protected>
      //   ),
      //   loader: async ({ params, request }) => {
      //       console.log('Params: ', params.contactId)
      //       console.log(request)
      //       return [{'name': 'Manuel'}, {'name': 'Pedro'}, {'name': 'Juan'}];
      //   },
      // },
      {
        element: (
          <Protected requiresLogin={true}>
            <Layout/>
          </Protected>
        ), children: [
          {
            path: "/",
            element: <Dashboard/>,
          }, 
          {
            path: "/users",
            element: <User/>,
          }, 
          {
            path: "/conf",
            element: <Api/>,
          }, 
          {
            path: "/users/create",
            element: <UserCreate/>,
          },
          {
            path: "/users/password",
            element: <UserPassword/>,
          },
          {
            path: "/users/:id",
            element: <UserUpdate/>,
          },
          {
            path: "/orders",
            element: <Order/>,
          },
          {
            path: "/orders/recurrent",
            element: <Recurrent/>,
          },
          {
            path: "/orders/update/:id",
            element: <OrderUpdate/>,
          },
          {
            path: "/notifications",
            element: <Notification/>,
          },
          {
            path: "/notifications/endpoints",
            element: <Endpoint/>,
          },
          {
            path: "/notifications/users/:notificationId",
            element: <NotificationEndpoint/>,
          },
          {
            path: "/notifications/create",
            element: <NotificationCreate/>,
          },
          {
            path: "/shops",
            element: <Webshop/>,
          },
          {
            path: "/shops/create",
            element: <WebshopCreate/>,
          },
          {
            path: "/shops/:id",
            element: <WebshopUpdate/>,
          },
        ]
      },
      {
        path: "recovery",
        errorElement: <ErrorPage/>,
        element: (
          <Protected requiresLogin={false}>
            <RecoveryPassword/>
          </Protected>
        ),
      },
      {
        path: "resetPassword",
        errorElement: <ErrorPage/>,
        element: (
          <Protected requiresLogin={false}>
            <ResetPassword/>
          </Protected>
        ),
      },
      {
        path: "login",
        errorElement: <ErrorPage/>,
        element: (
          <Protected requiresLogin={false}>
            <Login/>
          </Protected>
        ),
      },
      
    ],
  },
  
]);

export default routes;