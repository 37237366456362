import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './slices/counter/counterSlice';
import authReducer from './slices/auth/authSlice';
import modalConfirmationReducer from './slices/modal/modalConfirmationSlice';
import modalCreateJwtReducer from './slices/modal/modalCreateJwtSlice';
import modalShowJwtReducer from './slices/modal/modalShowJwtSlice';
import modalCreateIpReducer from './slices/modal/modalCreateIpSlice';

export default configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    modalConfirmation: modalConfirmationReducer,
    modalCreateJwt: modalCreateJwtReducer,
    modalShowJwt: modalShowJwtReducer,
    modalCreateIp: modalCreateIpReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
})