import CTable from 'components/table/ctable';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const User = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ data: null, total: 0, columns: ['email', 'role', 'is_active', 'updated_date', 'created_date'], sort: false, pagination: {'active': 1, 'pageSize': 0, 'numPages': 0}, actions: {'update': '/users/{id}', /*'delete': null*/} });
    let [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') ?? 1);

    useEffect(() => {
        (async() => {
            let url = `${environment.apiUrl}/getUsers?`; 
            let sParams = { ...Object.fromEntries(searchParams.entries()) };

            if (empty(sParams?.page)) {
                sParams.page = 1;
            }

            let params = new URLSearchParams(sParams);

            url += params.toString();
            
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();

            if (!empty(res)) {
                const page = res?.page;
                const pageSize = res?.pageSize;
                const numPages = res?.numPages;
                const items = res?.items;
                const total = res?.total;

                const params = { ...Object.fromEntries(searchParams.entries()) };

                if (page != 1) {
                    params.page = page;
                }

                //si modificamos URLSearchParams tenemos que evitar cambiar tambien setSearchParams
                //setSearchParams(params, { replace: true });
                setData({ ...data, data: items, total: total, sort: false, pagination: {'active': page, 'pageSize': pageSize, 'numPages': numPages} });
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         console.log('entraaaa')
    //         let d = [
    //             {
    //                 'id': 4,
    //                 'email': 'Luis',
    //                 'role': 'Trinidad',
    //                 'is_active': 'manueltg',
    //             }, 
    //             {
    //                 'id': 5,
    //                 'email': 'Domingo',
    //                 'role': 'Terrón',
    //                 'is_active': 'pepeT',
    //             }, 
    //             {
    //                 'id': 6,
    //                 'email': 'Carmen',
    //                 'role': 'Morón',
    //                 'is_active': 'carm1989',
    //             }
    //         ];
    //         setData({ data: d, columns: ['email', 'role', 'is_active'], sort: false, pagination: {'active': 1, 'pageSize': 9}, actions: {'update': '/users/{id}', 'delete': null} })
    //     }, 5000);
    //     return () => clearTimeout(timer);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <>
            <div className="container-fluid p-0">

                <div className="mb-3">
                    <h1 className="h3 d-inline align-middle">
                        <strong>{t('users')}</strong>
                    </h1>
                </div>

                <div className="row">
                    <div className="col-12 d-flex">
                        <div className="w-100">
                            <div className="row">
                                <div className="card">

                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <CTable data={data} setCurrentPage={setCurrentPage}></CTable>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default User;