import CTable from 'components/table/ctable';
import { JwtTokenContext } from 'context/context';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const Webshop = () => {
    const [jwtToken, setJwtValue, rol] = useContext(JwtTokenContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ data: null, total: 0, columns: ['domain', 'name', 'commission', 'android_package', 'ios_package', 'multi_country', 'is_active', 'updated_date', 'created_date'], sort: false, pagination: {'active': 1, 'pageSize': 0, 'numPages': 0}, actions: {'update_webshop': '/shops/{domain}', 'delete_order': null} });
    let [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') ?? 1);

    useEffect(() => {
        (async() => {
            let url = `${environment.apiUrl}/getWebshops?`; 
            let sParams = { ...Object.fromEntries(searchParams.entries()) };

            if (empty(sParams?.page)) {
                sParams.page = 1;
            }

            let params = new URLSearchParams(sParams);

            url += params.toString();
            
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();

            if (!empty(res)) {
                const page = res?.page;
                const pageSize = res?.pageSize;
                const numPages = res?.numPages;
                const items = res?.items;
                const total = res?.total;

                const params = { ...Object.fromEntries(searchParams.entries()) };

                if (page != 1) {
                    params.page = page;
                }
                
                //si modificamos URLSearchParams tenemos que evitar cambiar tambien setSearchParams
                //setSearchParams(params, { replace: true });
                setData({ ...data, data: items, total: total, sort: false, pagination: {'active': page, 'pageSize': pageSize, 'numPages': numPages} });
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return (
        <>
            <div className="container-fluid p-0">

                <div className="mb-3">
                    <h1 className="h3 d-inline align-middle">
                        <strong>{t('shops')}</strong>
                    </h1>
                </div>

                <div className="row">
                    <div className="col-12 d-flex">
                        <div className="w-100">
                            <div className="row">
                                <div className="card">

                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <CTable data={data} setCurrentPage={setCurrentPage} deleteAction={rol == 'ADMIN' ? '/deleteWebshop' : null}></CTable>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default Webshop;