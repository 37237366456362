import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hide, fetchCreateJwt } from 'redux/slices/modal/modalCreateJwtSlice';
import * as dayjs from 'dayjs';

function ModalCreateJwt({ url, reload: setReload }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const handleClose = () => dispatch(hide());

    const show = useSelector((state) => {
        return state.modalCreateJwt.show;
    });

    const title = useSelector((state) => state.modalCreateJwt.title);
    const message = useSelector((state) => state.modalCreateJwt.message);
    const deleteId = useSelector((state) => state.modalCreateJwt.deleteId);

    const onCreate = async () => {
        const response = await dispatch(fetchCreateJwt({ url: url}));
        if (response.payload?.success === true) {
            dispatch(hide());
            toast.success(t('success_create_jwt'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setReload(dayjs().unix());

        } else {
            dispatch(hide());
            toast.error(t('error_create_jwt'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            { t('no') }
          </Button>
          <Button variant="danger" onClick={() => onCreate()}>{ t('yes') }</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCreateJwt;