import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
//construir desde scss quitando la version de bootstrap que tenga y sustituyendo por la nuestra
import 'assets/css/app.css';
import 'assets/css/custom.css';
import routes from 'routes/routes';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import Login from './pages/login/login';

const router = routes;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    { /* Evitar renderizado doble */}
    { /* <React.StrictMode> */ }
      <RouterProvider router={router} />
    { /* </React.StrictMode> */ }
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
