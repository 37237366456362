import LoadingIcon from 'components/loading/loading-icon';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import * as dayjs from 'dayjs';

const OrderUpdate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        clearErrors,
        setError,
        formState,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });

    const getInitialValues = (async () => {
        try {
            //nos traemos los datos iniciales
            const response = await fetch(`${environment.apiUrl}/getOrder/${id}`, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();  
            
            if (!empty(res) && empty(res?.error)) {
                setValue('OrderUpdateForm.device_uid', res?.device_uid);
                setValue('OrderUpdateForm.email', res?.email);
                setValue('OrderUpdateForm.order_number', res?.order_number);
                setValue('OrderUpdateForm.order_status', res?.order_status);
                setValue('OrderUpdateForm.order_total', res?.order_total);
                setValue('OrderUpdateForm.order_net_total', res?.order_net_total);
                setValue('OrderUpdateForm.currency', res?.currency);
                setValue('OrderUpdateForm.country', res?.country);
                setValue('OrderUpdateForm.platform', res?.platform);
                setValue('OrderUpdateForm.url', res?.url);
                setValue('OrderUpdateForm.updated_date', dayjs(res?.updated_date).format('DD-MM-YYYY HH:mm:ss'));
                setValue('OrderUpdateForm.created_date', dayjs(res?.created_date).format('DD-MM-YYYY HH:mm:ss'));  
            } else {
                toast.error(t('not_found'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                navigate('/orders');
            }
        
        } catch (e) {
            console.error(e);
        }
    });

    useEffect(() => {
        getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onSubmit = async ({OrderUpdateForm: data}) => {
        // async request which may result error
        try {
            setLoading(true);
            const response = await fetch(`${environment.apiUrl}/updateOrder`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...data, id: id }), // body data type must match "Content-Type" header
            });
            const res = await response.json();

            if (res == false || Array.isArray(res) && res.length == 0) {
                toast.success(t('success_save'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                await getInitialValues();

                navigate('/orders');

            } else {
                clearErrors();
                //recorremos los errores
                for (const error of res) {
                    //tenemos que rellenar los errores
                    for (const e in error.constraints) {
                        setError(`OrderUpdateForm.${error.property}`, { type: 'custom', message: error.constraints[e] })      
                    }
                }
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false); 
    }

    return (
        <>
            <div className="container-fluid p-0">

                <div className="row">
                    <div className="col-12 col-xl-6">
                        <div className="mb-3">
                            <h1 className="h3 d-inline align-middle">
                                <strong>{t('update_order')}</strong>
                            </h1>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="m-sm-3">

                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.device_uid">
                                            <Form.Label>{t('device_uid')}</Form.Label>
                                            <Form.Control type="device_uid" 
                                            {...register('OrderUpdateForm.device_uid', { required: t('required_field', { field: t('device_uid') }) })} 
                                            disabled={true} 
                                            placeholder={t('device_uid')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.device_uid && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.device_uid?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.email">
                                            <Form.Label>{t('email')}</Form.Label>
                                            <Form.Control type="email" 
                                            {...register('OrderUpdateForm.email', { required: t('required_field', { field: t('email') }) })} 
                                            disabled={true} 
                                            placeholder={t('email')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.email && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.email?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.order_number">
                                            <Form.Label>{t('order_number')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.order_number', { required: t('required_field', { field: t('order_number') }) })} 
                                            disabled={true} 
                                            placeholder={t('order_number')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.order_number && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.order_number?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.order_status">
                                            <Form.Label>{t('order_status')}</Form.Label>
                                            <Form.Select aria-label={t('select_status')} {...register('OrderUpdateForm.order_status', { required: t('required_field', { field: t('order_status') }) })} placeholder={t('order_status')} >
                                                <option value="">{t('select_status')}</option>
                                                {
                                                    environment.orderStatus.map((value) => 
                                                        <option key={value.value} value={value.value}>{value.label}</option>
                                                    )
                                                }
                                            </Form.Select>
                                            { errors.OrderUpdateForm?.order_status && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm?.order_status.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.platform">
                                            <Form.Label>{t('platform')}</Form.Label>
                                            <Form.Select aria-label={t('select_platform')} {...register('OrderUpdateForm.platform', { required: t('required_field', { field: t('platform') }) })} placeholder={t('platform')} >
                                                <option value="">{t('select_platform')}</option>
                                                <option value="android">Android</option>
                                                <option value="ios">iOS</option>
                                            </Form.Select>
                                            { errors.OrderUpdateForm?.platform && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm?.platform.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.order_total">
                                            <Form.Label>{t('order_total')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.order_total', { required: t('required_field', { field: t('order_total') }) })} 
                                            disabled={true} 
                                            placeholder={t('order_total')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.order_total && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.order_total?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.order_net_total">
                                            <Form.Label>{t('order_net_total')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.order_net_total', { required: t('required_field', { field: t('order_net_total') }) })} 
                                            disabled={true} 
                                            placeholder={t('order_net_total')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.order_net_total && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.order_net_total?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.currency">
                                            <Form.Label>{t('currency')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.currency', { required: t('required_field', { field: t('currency') }) })} 
                                            disabled={true} 
                                            placeholder={t('currency')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.currency && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.currency?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.country">
                                            <Form.Label>{t('country')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.country', { required: t('required_field', { field: t('country') }) })} 
                                            disabled={true} 
                                            placeholder={t('country')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.country && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.country?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.url">
                                            <Form.Label>{t('url')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.url', { required: t('required_field', { field: t('url') }) })} 
                                            disabled={true} 
                                            placeholder={t('url')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.url && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.url?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.updated_date">
                                            <Form.Label>{t('updated_date')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.updated_date', { required: t('required_field', { field: t('updated_date') }) })} 
                                            disabled={true} 
                                            placeholder={t('updated_date')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.updated_date && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.updated_date?.message}</Badge> }
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="OrderUpdateForm.created_date">
                                            <Form.Label>{t('created_date')}</Form.Label>
                                            <Form.Control type="text" 
                                            {...register('OrderUpdateForm.created_date', { required: t('required_field', { field: t('created_date') }) })} 
                                            disabled={true} 
                                            placeholder={t('created_date')} 
                                            onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                            />
                                            { errors.OrderUpdateForm?.created_date && <Badge bg="danger" className="mt-2">{errors.OrderUpdateForm.created_date?.message}</Badge> }
                                        </Form.Group>
                                        
                                        <Form.Group className="mt-3 d-grid gap-2">
                                            <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-save"></i>&nbsp;{t('save')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default OrderUpdate;