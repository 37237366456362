import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from 'locales/common';

// import Backend from 'i18next-http-backend';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (cb) => {
    
    // const STORE_LANGUAGE_KEY = 'language';
    try {
      //get stored language from Async storage
    //   await AsyncStorage.getItem(STORE_LANGUAGE_KEY).then((language) => {
        
    //     if (language) {
    //       //if language was stored before, use this language in the app
    //       return cb(language);
    //     } else {
    //       //if language was not stored yet, use device's locale
    //       console.log('NENNTRAA')
    //       return cb(getLocales()?.[0]?.languageCode);
    //     }

    //   });

        return cb('es');

    } catch (error) {
      console.log("Error reading language", error);
    }
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  .use(languageDetector)
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, 
    fallbackLng: 'es',
    ns: ['common'],
    defaultNS: 'common',
    //with this key ignore languageDetector function
    // lng: 'es', 
    //compatibilityJSON: 'v3',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    //load directly from here
    resources: {
      es: {
        common: {
            // today: 'Hoy',
            ...common?.es
        },
      },
      en: {
        common: {
            // today: 'Hoy',
            ...common?.en
        },
      }
    },
  });


export default i18n;