import { ModalConfirmContext } from 'context/context';
import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function ModalNotificationConfirm({ title, message }) {
    const { setShow, show, onSubmit } = useContext(ModalConfirmContext);
    const { t } = useTranslation();
    
    const handleClose = () => {
      setShow(false);
    }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{__html: message}}></Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            { t('no') }
          </Button>
          <Button variant="danger" onClick={() => onSubmit()}>{ t('yes') }</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalNotificationConfirm;