import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Badge, Form } from 'react-bootstrap';
import LoadingIcon from 'components/loading/loading-icon';
import { fetchCreateIp, hideModalCreateIp } from 'redux/slices/modal/modalCreateIpSlice';
import * as dayjs from 'dayjs';

function ModalCreateIp({ url, reload: setReload }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        clearErrors,
        setError,
        getValues,
        setValue,
        formState,
        reset,
        formState: { errors, isDirty, dirtyFields },
    } = useForm();
    const formRef = useRef();
 
    const handleClose = () => {
        reset();
        dispatch(hideModalCreateIp());
    };

    const show = useSelector((state) => {
        return state.modalCreateIp.show;
    });

    const title = useSelector((state) => state.modalCreateIp.title);
    const message = useSelector((state) => state.modalCreateIp.message);

    const onSubmit = async ({ CreateIpForm: data }) => {
        const response = await dispatch(fetchCreateIp({ url: url, ...data }));
        if (Array.isArray(response.payload) && response.payload.length == 0) {
            reset();
            dispatch(hideModalCreateIp());
            toast.success(t('success_create_ip'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            setReload(dayjs().unix());

        } else {
            clearErrors();
            //reseteamos para bloquear el boton de envío
            reset(null, { keepDirty: false });
            //setError(`CreateIpForm.ip_address`, { type: 'custom', message: t('password_is_not_valid') });
            //recorremos los errores
            if (Array.isArray(response.payload)) {
                for (const error of response.payload) {
                    //tenemos que rellenar los errores
                    for (const e in error.constraints) {
                        setError(`CreateIpForm.${error.property}`, { type: 'custom', message: error.constraints[e] })      
                    }
                }
            }
        }
    }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{message}</p>
            <Form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <Form.Group className="mb-3" controlId="CreateIpForm.ip_address">
                    <Form.Label className='required'>{t('ip_address')}</Form.Label>
                    <Form.Control type="text" {...register('CreateIpForm.ip_address', { 
                        required: t('required_field', { field: t('ip_address') }),
                        pattern: {
                            value: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/,
                            message: t('ip_address_is_not_valid'),
                        }, 
                    })} 
                    placeholder={t('ip_address')} 
                    onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                    onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                    />
                    { errors.CreateIpForm?.ip_address && <Badge bg="danger" className="mt-2">{errors.CreateIpForm?.ip_address.message}</Badge> }
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            { t('close') }
          </Button>
          <Button onClick={(e) => formRef.current.requestSubmit() } variant="success" disabled={loading || !isDirty}><i className="bi bi-save"></i>&nbsp;{t('save')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCreateIp;