import { empty } from 'helper/helper';
import { useState } from "react";
import jwt_decode from "jwt-decode";

export const useLocalStorage = (keyName, defaultValue) => {
  const [email, setEmail] = useState(null);
  const [rol, setRol] = useState(null);
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (keyName == 'shop') {
        return value;
      }
      if (value) {  
        //colocamos aqui email y rol para que vayan sincronizados con el jwt
        const jwt = jwt_decode(value);
        setEmail(jwt?.email);
        setRol(jwt?.rol);
        return value;
      } else {
        //colocamos aqui email y rol para que vayan sincronizados con el jwt
        const jwt = jwt_decode(defaultValue);
        setEmail(jwt?.email);
        setRol(jwt?.rol);
        window.localStorage.setItem(keyName, defaultValue);
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  
  const setValue = (newValue) => {
    try {
      if (empty(newValue)) {
        window.localStorage.removeItem(keyName);
        //colocamos aqui email y rol para que vayan sincronizados con el jwt
        if (keyName == 'jwtToken') {
          setEmail(null);
          setRol(null);
        }
      } else {
        window.localStorage.setItem(keyName, newValue);
        //colocamos aqui email y rol para que vayan sincronizados con el jwt
        if (keyName == 'jwtToken') {
          const jwt = jwt_decode(newValue);
          setEmail(jwt?.email);
          setRol(jwt?.rol);
        }
      }
    } catch (err) {
        console.error(err)
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue, rol, email];
};