import ModalCreateJwt from 'components/modal/moda-create-jwt';
import ModalCreateIp from 'components/modal/modal-create-ip';
import CTable from 'components/table/ctable';
import { ApiJwtContext } from 'context/context';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showModalCreateIp } from 'redux/slices/modal/modalCreateIpSlice';
import { show } from 'redux/slices/modal/modalCreateJwtSlice';

const Api = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [jwtData, setJwtData] = useState({ data: null, total: 0, columns: ['jwt', 'created_date'], sort: false, pagination: false, actions: { 'show_jwt': null, 'delete': null } });
    const [ipData, setIpData] = useState({ data: null, total: 0, columns: ['ip_address', 'created_date'], sort: false, pagination: false, actions: { 'delete': null } });
    const dispatch = useDispatch();
    const [reload, setReload] = useState(0);
    
    useEffect(() => {
        (async() => {
            let url = `${environment.apiUrl}/apiData?`; 
        
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();

            if (!empty(res)) {
                const jwtItems = res?.jwt;
                setJwtData({ ...jwtData, data: jwtItems, total: jwtItems.length, hiddenJwt: true });
                const ipItems = res?.ip;
                setIpData({ ...ipData, data: ipItems, total: ipItems.length, hiddenJwt: true });
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const createJwt = () => {
        dispatch(show({ title: t('confirm_new_jwt_header'), message: t('confirm_new_jwt_message') }))
    };

    const createIp = () => {
        dispatch(showModalCreateIp({ title: t('create_new_ip_address'), message: '' }))
    };

    return (
        <>
            <ApiJwtContext.Provider value={[jwtData, setJwtData, setReload]}>
                <div className="container-fluid p-0">

                    <div className="mb-3">
                        <h1 className="h3 d-inline align-middle">
                            <strong>{t('api')}</strong>
                        </h1>
                    </div>

                    <div className="row">
                        <div className="col-12 d-flex">
                            <div className="w-100">
                                <div className="row">
                                    <div className="card">

                                        <div className="card-body">
                                            <div className='d-flex justify-content-end'>
                                                <Button variant="primary" onClick={createJwt}>
                                                    <i className="bi bi-plus-circle"></i>&nbsp;{ t('create_jwt') }
                                                </Button>
                                            </div>
                                            <h4 className="d-inline align-middle">
                                                <strong>{t('api_access')}</strong>
                                            </h4>
                                            <div className="m-sm-3 table-responsive">
                                                <CTable data={jwtData} showJwtAction={'/showJwt'} deleteAction={'/deleteJwt'} type={'jwt'} reload={setReload}></CTable>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12 d-flex">
                            <div className="w-100">
                                <div className="row">
                                    <div className="card">

                                        <div className="card-body">
                                            <div className='d-flex justify-content-end'>
                                                {
                                                    <Button variant="primary" disabled={!empty(ipData?.data) && ipData?.data?.length > 2} onClick={createIp}>
                                                        <i className="bi bi-plus-circle"></i>&nbsp;{ t('create_ip') }
                                                    </Button> 
                                                }
                                            </div>
                                            <h4 className="d-inline align-middle">
                                                <strong>{t('ip_addresses')}</strong>
                                            </h4>
                                            <div className="m-sm-3 table-responsive">
                                                <CTable data={ipData} deleteAction={'/deleteIp'} type={'ip'} reload={setReload}></CTable>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <ModalCreateJwt url={'/createJwt'} reload={setReload}></ModalCreateJwt>
                    <ModalCreateIp url={'/createIp'} reload={setReload}></ModalCreateIp>

                </div>
            </ApiJwtContext.Provider>
        </>
    );
}

export default Api;