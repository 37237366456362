import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hide, fetchDeleteRow } from 'redux/slices/modal/modalConfirmationSlice';
import * as dayjs from 'dayjs';
import { empty } from 'helper/helper';

function ModalConfirm({ url, reload: setReload }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const handleClose = () => dispatch(hide());

    const show = useSelector((state) => {
        return state.modalConfirmation.show;
    });

    const title = useSelector((state) => state.modalConfirmation.title);
    const message = useSelector((state) => state.modalConfirmation.message);
    const deleteId = useSelector((state) => state.modalConfirmation.deleteId);
    const type = useSelector((state) => state.modalConfirmation.type);
    const newUrl = useSelector((state) => state.modalConfirmation.url);
    const [searchParams, setSearchParams] = useSearchParams();

    if (!empty(newUrl)) {
      url = newUrl;
    }

    const onDelete = async () => {
        const response = await dispatch(fetchDeleteRow({ url: url, deleteId: deleteId }));
        if (response.payload === true) {
            dispatch(hide());
            toast.success(t('success_delete'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } else {
            dispatch(hide());
            toast.error(t('delete_error'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        //forzamos la actualizacion de la tabla
        if (!['jwt', 'ip'].includes(type)) {
          setSearchParams({ ...Object.fromEntries(searchParams.entries()), time: Date.now() }, { replace: false });
        } else {
          setReload(dayjs().unix());
        }
        
    }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            { t('no') }
          </Button>
          <Button variant="danger" onClick={() => onDelete()}>{ t('yes') }</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalConfirm;