/*eslint no-undef: "off"*/
import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { useState } from 'react';
import LoadingIcon from 'components/loading/loading-icon';
import { environment } from 'environments/environment';
import { toast } from 'react-toastify';
import { empty } from 'helper/helper';

const ResetPassword = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        getValues,
        formState,
        watch,
        reset,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setRecoveryToken] = useState(searchParams.get('token') ?? null);

    useEffect(() => {
        if (empty(token)) {
            toast.error(t('token_is_not_valid'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            navigate('/login');
        } else {
            (async() => {
                let url = `${environment.apiUrl}/checkResetPassword`; 
                
                const response = await fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        recoveryToken: token
                    })
                });
                
                const res = await response.json();

                if (!res) {
                    toast.error(t('token_is_not_valid'), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    navigate('/login');
                } 

            })();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const onSubmit = async ({ResetPasswordForm: data}) => {
        // async request which may result error
        try {   
            setLoading(true);

            let url = `${environment.apiUrl}/resetPassword`; 
            
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    recoveryToken: token,
                })
            });
            
            const res = await response.json();

            if (res) {
                toast.success(t('password_updated_successfully'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                //reseteamos el formulario
                reset();

                navigate('/login');
            } else {
                toast.error(t('password_updated_error'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false);
    }

    let password = watch('ResetPasswordForm.password');

    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">

                            <div className="text-center mt-4">
                                <h1 className="h2">{t('reset_password')}</h1>
                                <p className="lead">
                                    {t('enter_your_new_password')}
                                </p>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-3">

                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3" controlId="ResetPasswordForm.password">
                                                <Form.Label>{t('password')}</Form.Label>
                                                <Form.Control type="password" {...register('ResetPasswordForm.password', { 
                                                    required: t('required_field', { field: t('password') }),  
                                                    minLength: {
                                                        value: 6,
                                                        message: t('min_length', { field: t('password'), length: 6 })
                                                    }
                                                })} 
                                                placeholder={t('password')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.ResetPasswordForm?.password && <Badge bg="danger" className="mt-2">{errors.ResetPasswordForm.password?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="ResetPasswordForm.repeat_password">
                                                <Form.Label>{t('repeat_password')}</Form.Label>
                                                <Form.Control type="password" {...register('ResetPasswordForm.repeat_password', { 
                                                required: t('required_field', { field: t('repeat_password'), 
                                                minLength: {
                                                    value: 6,
                                                    message: t('min_length', { field: t('password'), length: 6 })
                                                },
                                                validate: (value) => {                    
                                                    if (password != value) {
                                                        return t('passwords_dont_match');
                                                    }
                                                },
                                                }) })} 
                                                placeholder={t('repeat_password')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.ResetPasswordForm?.repeat_password && <Badge bg="danger" className="mt-2">{errors.ResetPasswordForm.repeat_password?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mt-3 d-grid gap-2">
                                                <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-save"></i>&nbsp;{t('save')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                            </Form.Group>
                                        </Form>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                { t('do_you_want_to_access') } <Link to="/login">{t('sign_in')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ResetPassword;
