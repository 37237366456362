import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { useState } from 'react';
import LoadingIcon from 'components/loading/loading-icon';
import { useLazyLoadExtScript } from 'hooks/useLazyLoadExtScript';
import config from 'config/config';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogin, login, logout } from 'redux/slices/auth/authSlice';
import { toast } from 'react-toastify';
import { empty } from 'helper/helper';
import { JwtTokenContext } from 'context/context';
import { environment } from 'environments/environment';

const Login = () => {

    useLazyLoadExtScript(config.recaptchaSRC);
    const [jwtToken, setJwtValue] = useContext(JwtTokenContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState,
        reset,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const count = useSelector((state) => state.counter.value);
    // const auth = useSelector((state) => state.auth.token);

    useEffect(() => {
        (async () => {
            if (!empty(jwtToken)) {
                let url = `${environment.apiUrl}/logout`;
                const response = await fetch(url, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setJwtValue(null);
                dispatch(logout());
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async ({LoginForm: data}) => {
        // async request which may result error
        try {
            setLoading(true);
            let captchaToken = await window.grecaptcha.execute(config.recaptchaSiteKey, { action: 'login' });
            
            const response = await dispatch(fetchLogin(
                { 
                    ...data, 
                    captcha: captchaToken
                }
            ));

            if (response?.payload?.success && response?.payload?.data?.jwt) {
                setJwtValue(response.payload.data.jwt);
                navigate('/');
            } else {
                toast.error(t('login_error'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false); 
    }

    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">

                            <div className="text-center mt-4">
                                <h1 className="h2">{t('welcome_back')}</h1>
                                <p className="lead">
                                    {t('sign_in_to_continue')}
                                </p>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-3">

                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3" controlId="LoginForm.email">
                                                <Form.Label>{t('email')}</Form.Label>
                                                <Form.Control type="email" 
                                                {...register('LoginForm.email', { required: t('required_field', { field: t('email') }) })} 
                                                placeholder={t('email')}
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.LoginForm?.email && <Badge bg="danger" className="mt-2">{errors.LoginForm.email?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="LoginForm.password">
                                                <Form.Label>{t('password')}</Form.Label>
                                                <Form.Control type="password" 
                                                {...register('LoginForm.password', { required: t('required_field', { field: t('password') }) })} 
                                                placeholder={t('password')} 
                                                onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.LoginForm?.password && <Badge bg="danger" className="mt-2">{errors.LoginForm?.password.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mt-3 d-grid gap-2">
                                                <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-box-arrow-in-right"></i>&nbsp;{t('sign_in')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                            </Form.Group>
                                        </Form>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                { t('forgot_your_password') } <Link to="/recovery">{t('forgot_password')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Login;