import CTable from 'components/table/ctable';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import Badge from 'themes/overrides/Badge';
import { ShopContext } from 'context/context';
import Select from 'react-select';

registerLocale('es', es);

dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const Notification = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        reset,
        trigger,
        formState,
        formState: { errors, isDirty, dirtyFields },
    } = useForm();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [minStartDate, setMinStartDate] = useState(new Date('2023-07-31'));
    const [maxStartDate, setMaxStartDate] = useState(new Date());
    const [minEndDate, setMinEndDate] = useState(new Date('2023-07-31'));
    const [maxEndDate, setMaxEndDate] = useState(new Date());
    const [data, setData] = useState({ data: null, total: 0, columns: ['email', 'webshop_id', 'device_uid', 'detail', 'scheduled', 'updated_date', 'created_date'], sort: false, pagination: {'active': 1, 'pageSize': 0, 'numPages': 0}, actions: { 'notification_endpoint': '/notifications/users/{id}', 'delete_notification': null } });
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') ?? 1);    
    const {shop} = useContext(ShopContext);  
    //se puede hacer con hooks tambien
    //const { field } = useController({ control, name: 'SearchForm.start_date', defaultValue: null });  
    const [childShops, setChildShops] = useState(null);

    const getInitialValues = (async () => {
        try {
            let url = `${environment.apiUrl}/getChildShops?`;

            let params = new URLSearchParams();

            params.append('domain', shop);

            url += params.toString();

            //nos traemos los datos iniciales
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
            setChildShops(res);

        } catch(e) {
            console.error(e);
        }
    });

    useEffect(() => {
        getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shop]);

    useEffect(() => {
        (async() => {
            if (shop) {
                let url = `${environment.apiUrl}/getNotifications?`;
                let sParams = { ...Object.fromEntries(searchParams.entries()) };
    
                if (empty(sParams?.page)) {
                    sParams.page = 1;
                }
                
                let params = new URLSearchParams(sParams);

                params.append('domain', shop);

                url += params.toString();

                //reseteamos los valores del formulario
                resetFormFields();

                //colocamos los valores iniciales del input
                params.forEach((value, key) => {
                    if (key != 'webshop_id') {
                        setValue('SearchForm.' + key, value);
                    } else {
                        let vObject = [];
                        let label = childShops?.find((e) => e.value == value)?.label ?? '';
                        if (!empty(value) && !empty(label)) {
                            vObject = { value: value, label: label };   
                        }
                        setValue('SearchForm.' + key, vObject);
                    }              
                });

                const response = await fetch(url, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Content-Type': 'application/json',
                        'Timezone': dayjs.tz.guess(),
                    },
                });
        
                const res = await response.json();

                if (!empty(res)) {
                    const page = res?.page;
                    const pageSize = res?.pageSize;
                    const numPages = res?.numPages;
                    const items = res?.items;
                    const sortColumn = res?.sortColumn;
                    const sortOrder = res?.sortOrder; 
                    const total = res?.total;

                    const params = { ...Object.fromEntries(searchParams.entries()) };

                    if (page != 1) {
                        params.page = page;
                    }

					//si modificamos URLSearchParams tenemos que evitar cambiar tambien setSearchParams
                    //setSearchParams(params, { replace: true });
                    setData({ ...data, total: total, data: items, sort: false, pagination: {'active': page, 'pageSize': pageSize, 'numPages': numPages} });
                }
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, shop, childShops]);

    const onSubmit = async ({SearchForm: data}) => {
        //reseteamos la pagina al buscar
        setCurrentPage(1);
        setSearchParams({ page: 1, search: data?.search ?? '', webshop_id: data?.webshop_id?.value ?? '', detail: data?.detail ?? '', scheduled: data?.scheduled ?? '', start_date: data?.start_date ?? '', end_date: data?.end_date ?? '' }, { replace: false });
    }

    const resetFormFields = () => {
        const formName = 'SearchForm';
        const data = {};
        const keys = Object.keys(getValues(formName));
        for (const key of keys) {
            data[`${formName}.${key}`] = '';
        }
        //reseteamos tambien los estados
        setStartDate('');
        setMinEndDate('');
        setEndDate('');
        reset(data, { keepDirty: true });
    }

    const resetAllFields = () => {
        resetFormFields();
        return handleSubmit(onSubmit)();
    }

    return (
        <>
            <div className="container-fluid p-0">

                <div className="mb-3">
                    <h1 className="h3 d-inline align-middle">
                        <strong>{t('notifications')}</strong>
                    </h1>
                </div>
            
                <div className="row">
                    <div className="col-12 d-flex">
                        <div className="w-100">
                            <div className="row">
                                <div className="card">
                                    <div className="card-body">
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-xl-2">
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="validationCustom01">
                                                    <Form.Label>{t('search')}</Form.Label>
                                                    <Form.Control
                                                        {...register('SearchForm.search', {})} 
                                                        type="text" 
                                                        placeholder={t('search')}
                                                        onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                        onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                    />
                                                    { errors.SearchForm?.search && <Badge bg="danger" className="mt-2">{errors.SearchForm.search?.message}</Badge> }
                                                </Form.Group>
                                                {/* <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="validationCustom02">
                                                    <Form.Label>{t('webshop_id')}</Form.Label>
                                                    <Form.Control
                                                        {...register('SearchForm.webshop_id', {})} 
                                                        type="text" 
                                                        placeholder={t('webshop_id')}
                                                        onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                        onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                    />
                                                    { errors.SearchForm?.webshop_id && <Badge bg="danger" className="mt-2">{errors.SearchForm.webshop_id?.message}</Badge> }
                                                </Form.Group> */}
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="SearchForm.webshop_id">
                                                    <Form.Label>{t('webshop_id')}</Form.Label>
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'SearchForm.webshop_id'}
                                                        render={({ field }) => (
                                                            <Select
                                                                // inputClassName={'form-control'}
                                                                // height={34}
                                                                // className="react-select-container"
                                                                // classNamePrefix="react-select"
                                                                {...register('SearchForm.webshop_id', {})}
                                                                placeholder={t('select_child_shop')}
                                                                onChange={(value) => { 
                                                                    field.onChange(value); 
                                                                    //trigger('SearchForm.webshop_id'); 
                                                                }} 
                                                                value={field.value}
                                                                styles={{
                                                                    control: base => {
                                                                        let css = {
                                                                            ...base,
                                                                            height: (Array.isArray(field.value) && field.value.length > 0) ? base?.height : 34,
                                                                            minHeight: 34,
                                                                        }; 
                                                                        return css;
                                                                    }
                                                                }}
                                                                
                                                                isClearable
                                                                closeMenuOnSelect={true}
                                                                // defaultValue={selectedOption}
                                                                // onChange={setSelectedOption}
                                                                options={childShops}
                                                            />
                                                        )} 
                                                    />
                                                
                                                    { errors.SearchForm?.webshop_id && <Badge bg="danger" className="mt-2">{errors.SearchForm.webshop_id?.message}</Badge> }
                                                </Form.Group>
                                                <Form.Group as={Col} xl="2" className='mb-2 mt-xl-0 mb-xl-0' controlId="validationCustom03">
                                                    <Form.Label>{t('scheduled')}</Form.Label>
                                                    <Form.Select aria-label={t('select')}
                                                        {...register('SearchForm.scheduled', {})}
                                                    >
                                                        <option value=''>{t('select_scheduled')}</option>
                                                        <option value="1">{t('yes')}</option>
                                                        <option value="0">{t('no')}</option>
                                                    </Form.Select>
                                                    { errors.SearchForm?.is_active && <Badge bg="danger" className="mt-2">{errors.SearchForm.is_active?.message}</Badge> }
                                                </Form.Group>
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="validationCustom04">
                                                    <Form.Label>{t('start_date')}</Form.Label>
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'SearchForm.start_date'}
                                                        render={({ field }) => (
                                                            <DatePicker 
                                                                customInput={<Form.Control
                                                                type="text"
                                                            />} 
                                                                selected={startDate} 
                                                                onChange={(date) => { 
                                                                    setStartDate(date);
                                                                    setMinEndDate(date);
                                                                    if (date) {
                                                                        field.onChange(dayjs(date).format('DD-MM-YYYY'));
                                                                    } else {
                                                                       field.onChange(''); 
                                                                    }
                                                                }} 
                                                                value={field.value}
                                                                dateFormat="dd-MM-yyyy" 
                                                                locale="es"
                                                                minDate={minStartDate}
                                                                maxDate={maxStartDate}
                                                                placeholderText={t('start_date')}
                                                                closeOnScroll={true}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    { errors.SearchForm?.start_date && <Badge bg="danger" className="mt-2">{errors.SearchForm.start_date?.message}</Badge> }
                                                </Form.Group>
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="validationCustom05">
                                                    <Form.Label>{t('end_date')}</Form.Label>
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'SearchForm.end_date'}
                                                        render={({ field }) => (
                                                            <DatePicker customInput={<Form.Control
                                                                type="text"
                                                            />} selected={endDate} 
                                                                onChange={(date) => { 
                                                                    setEndDate(date);
                                                                    if (date) {
                                                                        field.onChange(dayjs(date).format('DD-MM-YYYY'));
                                                                    } else {
                                                                       field.onChange(''); 
                                                                    }
                                                                }} 
                                                                value={field.value}
                                                                dateFormat="dd-MM-yyyy" 
                                                                locale="es"
                                                                minDate={minEndDate}
                                                                maxDate={maxEndDate}
                                                                placeholderText={t('end_date')}
                                                                closeOnScroll={true}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    { errors.SearchForm?.end_date && <Badge bg="danger" className="mt-2">{errors.SearchForm.end_date?.message}</Badge> }
                                                </Form.Group>        
                                                <Form.Group as={Col} xl="2" className='align-items-end d-flex mb-2 mt-xl-0 mb-xl-0' controlId="validationCustom06">
                                                    <Button type="submit" className='w-100' disabled={loading || !isDirty}><i className="bi bi-search"></i>&nbsp;{t('search')} </Button>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-0">
                                                <Form.Group as={Col} xl="2" className='align-items-end d-flex' controlId="validationCustom06">
                                                    <Button type="button" className='w-100 btn-danger' onClick={() => resetAllFields()}><i className="bi bi-x-circle"></i>&nbsp;{t('reset')}</Button>
                                                </Form.Group>
                                            </Row>
                                            
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 d-flex">
                        <div className="w-100">
                            <div className="row">
                                <div className="card">

                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <CTable data={data} setCurrentPage={setCurrentPage} deleteAction={'/deleteNotification'}></CTable>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default Notification;