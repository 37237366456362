/*eslint no-undef: "off"*/
import { useEffect } from "react";

export const useLazyLoadExtScript = (url) => {
    
    useEffect(() => {
        if (!url) {
            return;
        }

        let script = document.querySelector(`script[src="${url}"]`);

        const handleScript = (e) => {
            window.grecaptcha = grecaptcha;
        };

        if (!script) {
            script = document.createElement("script");
            script.type = "application/javascript";
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
            script.addEventListener("load", handleScript);
            script.addEventListener("error", handleScript);
        }

        script.addEventListener("load", handleScript);
        script.addEventListener("error", handleScript);

        //cleanup function
        return () => {
            script.removeEventListener("load", handleScript);
            script.removeEventListener("error", handleScript);
        };
        
    }, [url]);

    return [];
};