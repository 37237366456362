import React from 'react';
import PropTypes from 'prop-types';

const LoadingPage = ({ name }) => {
    return (
        <div className="loader">
            {/* https://github.com/SamHerbert/SVG-Loaders */}
            <img src={`/svg-loaders/${name}.svg`} alt="icon-refresh"/>
        </div>
    );
}

LoadingPage.propTypes = {
  name: PropTypes.string.isRequired
}

export default LoadingPage;