import React, { useContext, useEffect, useRef, useState } from 'react';
import { Settings, PieChart, LogOut, Users, UserPlus, Archive, Bell, Smartphone, PlusSquare, Key, Lock, ShoppingCart, Plus, Info, RefreshCcw } from 'react-feather';
import SimpleBar from 'simplebar-react';
import classNames from "classnames";
import Dropdown from 'react-bootstrap/Dropdown';
import 'simplebar-react/dist/simplebar.min.css';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { empty } from 'helper/helper';
import { JwtTokenContext, ShopContext } from 'context/context';
import { environment } from 'environments/environment';
import { Col, Form, OverlayTrigger, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocalStorage } from 'hooks/useLocalStorage';
import Popover from 'react-bootstrap/Popover';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/slices/auth/authSlice';

const popover = (header, body) => (
    <Popover placement={'auto'}>
        <Popover.Header as="h6"><strong>{header}</strong></Popover.Header>
        <Popover.Body>
            <span dangerouslySetInnerHTML={{ __html: body}}></span>
        </Popover.Body>
    </Popover>
);

const Layout = () => {
    const [jwtToken, setJwtValue, rol, email] = useContext(JwtTokenContext);
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(false);
    const simpleBarRef = useRef();
    const { hash, pathname, search } = useLocation();
    const navigate = useNavigate();
    const [shops, setShops] = useState([]);
    const [refreshShops, setRefreshShops] = useState(0);
    const [shop, setShop] = useLocalStorage('shop', '');
    const formRef = useRef();
    const dispatch = useDispatch();
    
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        reset,
        formState,
        formState: { errors, isDirty, dirtyFields },
    } = useForm();
    
    const sidebarClassName = classNames({
        "sidebar": true,
        "js-sidebar": true,
        "collapsed": toggle
    });

    useEffect(() => {
        simpleBarRef.current.recalculate();
        //console.log(simpleBarRef)
    });

    useEffect(() => {
        if (empty(jwtToken)) {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwtToken]);

    useEffect(() => {
        (async () => {       
            let url = `${environment.apiUrl}/getWebshopSelect`;
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const res = await response.json();
            
            if (Array.isArray(res)) {
                setShops(res);
                //comprobamos si existe la tienda en el array, si no existe ponemos como predeterminada la primera tienda encontrada
                const found = res.find((value) => value.domain == shop);
                //si no lo hemos encontrado tenemos que poner como por defecto el primer valor
                if (!found && res.length > 0) {
                    setShop(res[0].domain);
                }
            } else {
                setShops([]);
            }   
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshShops, shop]);

    const flogout = async () => {
        //eliminamos el token del local storage
        //window.localStorage.removeItem('jwtToken');
        let url = `${environment.apiUrl}/logout`;
        await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
            },
        });
        setJwtValue(null);
        dispatch(logout());
        navigate('/login');
    };

    const onSubmit = async ({SearchForm: data}) => {
        setShop(data?.shop);
    }

    return (
        <>
            <ShopContext.Provider value={{shop, setShop, refreshShops, setRefreshShops}}>

                <div className="wrapper">
                    
                    <nav className={sidebarClassName}>

                        <SimpleBar ref={simpleBarRef}>

                                <Link to='/' className='sidebar-brand'>
                                    <span className="align-middle">{t('appname')}</span>
                                </Link>

                                <ul className="sidebar-nav">
                                    {
                                        ['ADMIN', 'USER'].includes(rol)
                                        ?
                                            <>
                                                <li className="sidebar-header">
                                                    {t('pages')}
                                                </li>

                                                <li className={
                                                    classNames({
                                                        'sidebar-item': true,
                                                        'active': pathname == '/',
                                                    })
                                                } >
                                                    <Link to="/" className='sidebar-link'>
                                                        <PieChart className="align-middle"/>
                                                        <span className="align-middle">{t('dashboard')}</span>
                                                    </Link>
                                                </li>
                                            </>
                                        : 
                                        null
                                    }
                                    <li className="sidebar-header">
                                        {t('notifications')}
                                    </li>

                                    <li className={
                                        classNames({
                                            'sidebar-item': true,
                                            'active': pathname == '/notifications',
                                        })
                                    } >
                                        <Link to="/notifications" className='sidebar-link'>
                                            <Bell className="align-middle"/>
                                            <span className="align-middle">{t('notifications')}</span>
                                        </Link>
                                        
                                    </li>

                                    <li className={
                                        classNames({
                                            'sidebar-item': true,
                                            'active': pathname == '/notifications/endpoints',
                                        })
                                    } >
                                        <Link to="/notifications/endpoints" className='sidebar-link'>
                                            <Smartphone className="align-middle"/>
                                            <span className="align-middle">{t('endpoints')}</span>
                                        </Link>
                                    </li>

                                    <li className={
                                        classNames({
                                            'sidebar-item': true,
                                            'active': pathname == '/notifications/create',
                                        })
                                    } >
                                        <Link to="/notifications/create" className='sidebar-link'>
                                            <PlusSquare className="align-middle"/>
                                            <span className="align-middle">{t('create_notification')}</span>
                                        </Link>
                                    </li>


                                    {
                                        ['ADMIN', 'USER', 'DEVELOPER'].includes(rol)
                                        ?
                                            <>
                                                <li className="sidebar-header">
                                                    { t('orders') }
                                                </li>

                                                <li className={
                                                    classNames({
                                                        'sidebar-item': true,
                                                        'active': pathname == '/orders',
                                                    })
                                                } >
                                                    <Link to="/orders" className='sidebar-link'>
                                                        <Archive className="align-middle"/>
                                                        <span className="align-middle">{t('orders')}</span>
                                                    </Link>
                                                </li>
                                                
                                                {
                                                    ['ADMIN', 'USER'].includes(rol) 
                                                    ?
                                                        <li className={
                                                            classNames({
                                                                'sidebar-item': true,
                                                                'active': pathname == '/orders/recurrent',
                                                            })
                                                        } >
                                                            <Link to="/orders/recurrent" className='sidebar-link'>
                                                                <RefreshCcw className="align-middle"/>
                                                                <span className="align-middle">{t('recurrent')}</span>
                                                            </Link>
                                                        </li>
                                                    : 
                                                    null
                                                }
                                            </>
                                        : 
                                        null
                                    }

                                    <li className="sidebar-header">
                                        { t('users') }
                                    </li>

                                    {
                                        rol == 'ADMIN' 
                                        ?   
                                            <>
                                                <li className={
                                                    classNames({
                                                        'sidebar-item': true,
                                                        'active': pathname == '/users',
                                                    })
                                                } >
                                                    <Link to="/users" className='sidebar-link'>
                                                        <Users className="align-middle"/>
                                                        <span className="align-middle">{ t('users') }</span>
                                                    </Link>
                                                </li>
                                
                                                <li className={
                                                    classNames({
                                                        'sidebar-item': true,
                                                        'active': pathname == '/users/create',
                                                    })
                                                } >
                                                    <Link to="/users/create" className='sidebar-link'>
                                                        <UserPlus className="align-middle"/>
                                                        <span className="align-middle">{ t('create_user') }</span>
                                                    </Link>
                                                </li>
                                            </>

                                        : 
                                            null
                                    }

                                    <li className={
                                        classNames({
                                            'sidebar-item': true,
                                            'active': pathname == '/users/password',
                                        })
                                    } >
                                        <Link to="/users/password" className='sidebar-link'>
                                            <Lock className="align-middle"/>
                                            <span className="align-middle">{ t('change_password') }</span>
                                        </Link>
                                    </li>

                                    {
                                        rol == 'ADMIN' 
                                        ?  
                                            <>
                                                <li className="sidebar-header">
                                                    { t('shops') }
                                                </li>

                                                <li className={
                                                    classNames({
                                                        'sidebar-item': true,
                                                        'active': pathname == '/shops',
                                                    })
                                                } >
                                                    <Link to="/shops" className='sidebar-link'>
                                                        <ShoppingCart className="align-middle"/>
                                                        <span className="align-middle">{ t('shops') }</span>
                                                    </Link>
                                                </li>

                                                <li className={
                                                    classNames({
                                                        'sidebar-item': true,
                                                        'active': pathname == '/shops/create',
                                                    })
                                                } >
                                                    <Link to="/shops/create" className='sidebar-link'>
                                                        <Plus className="align-middle"/>
                                                        <span className="align-middle">{ t('create_shop') }</span>
                                                    </Link>
                                                </li>
                                            </>
                                        : 
                                        null
                                    }

                                    <li className="sidebar-header">
                                        { t('settings') }
                                    </li>

                                    <li className={
                                        classNames({
                                            'sidebar-item': true,
                                            'active': pathname == '/conf',
                                        })
                                    } >
                                        <Link to="/conf" className='sidebar-link'>
                                            <Key className="align-middle"/>
                                            <span className="align-middle">{ t('api') }</span>
                                        </Link>
                                    </li>

                                    <li className="sidebar-item">
                                        <span className="sidebar-link" onClick={(event) => flogout()}>
                                            <LogOut className="align-middle"/> 
                                            <span className="align-middle">{ t('logout') }</span>
                                        </span>
                                    </li>

                                </ul>
                            
                        </SimpleBar>
                    
                    </nav>

                    <div className="main">
                        <nav className="navbar navbar-expand navbar-light navbar-bg">
                            <div className="sidebar-toggle js-sidebar-toggle" onClick={(event) => setToggle(!toggle)}>
                                <i className="hamburger align-self-center"></i>
                            </div>

                            <div className='d-block me-3'>
                                <img src="/favicon/favicon-32x32.png" alt={environment.name}/>
                            </div>

                            <div className='d-none d-sm-block'>
                                <div className='d-block'>
                                    <strong><i>{ !empty(email) ? email : '' }</i></strong>
                                </div>
                                <div className='d-block'>
                                    { !empty(rol) ? t(rol) : '' }
                                </div>
                            </div>

                            <div className='d-sm-none'>
                                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={popover(t('user_data'), `<div className="d-block"><strong><i>${email}</i></strong></div><div className="d-block">${t(rol)}</div>`) }>
                                    <i className='bi bi-person-circle' style={{ fontSize: '1.3rem' }}></i>
                                </OverlayTrigger>
                            </div>
                            
                            <div className="navbar-collapse collapse">
                                <ul className="navbar-nav navbar-align">

                                    <li className=''>
                                        <Form onSubmit={handleSubmit(onSubmit)} ref={formRef} className='me-4'>
                                            <Row className=""></Row>
                                                <Form.Group as={Col} xl="12" className='' controlId="SearchForm.shop">
                                                    <Form.Select aria-label={ t('select') }
                                                        value={shop}
                                                        {...register('SearchForm.shop', {})}
                                                        onChange={(e) => {
                                                            setValue(e.target.name, e.currentTarget.value);
                                                            formRef.current.requestSubmit(); 
                                                        }}
                                                    >   
                                                        {/* <option value=''>{t('select_shop')}</option> */}
                                                        {
                                                            shops.map((value, index) => (
                                                                <option key={value.domain} value={value.domain}>{ value.name }</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Form>
                                    </li>
                                    
                                    <li className="d-flex align-items-center">
                                        <Dropdown className='cursor-pointer'>
                                            <Dropdown.Toggle as="div">
                                            <Settings/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* <Dropdown.Item href="#/action-1" active>
                                                    Action
                                                </Dropdown.Item> */}
                                                {/* <Dropdown.Divider /> */}
                                                <Dropdown.Item onClick={(event) => flogout()}><i className="bi bi-box-arrow-right"></i>&nbsp;{t('logout')}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                    
                                </ul>
                            </div>
                        </nav>

                        <main className="content">
                            <Outlet/>
                        </main>

                        <footer className="footer">
                            <div className="container-fluid">
                                <div className="row text-muted">
                                    <div className="col-12 text-end">
                                        <p className="mb-0">
                                            <strong><Trans t={t} shouldUnescape={true}>copyright</Trans></strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </footer>

                    </div>
                </div>

            </ShopContext.Provider>
        </>
    );
}

export default Layout;