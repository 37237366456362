import { useTitle } from 'hooks/useTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteError } from "react-router-dom";

 const ErrorPage = () => {
  const error = useRouteError();
  const {t} = useTranslation();
  useTitle('Error');
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>{ error.status }</h1>
        </div>
        <h2>{error.statusText || error.message}</h2>
        <p>{error.data}</p>
        <Link to="/" className='sidebar-link'>{ t('go_to_homepage') }</Link>
      </div>
    </div>
  );
}

export default ErrorPage;