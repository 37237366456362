import React from 'react';
import PropTypes from 'prop-types';

const LoadingIcon = ({ name, style }) => {
    return (
        <span className="icon-refresh" style={{...style}}>
            {/* https://github.com/SamHerbert/SVG-Loaders */}
            <img src={`/svg-loaders/${name}.svg`} alt="icon-refresh"/>
        </span>
    );
}

LoadingIcon.propTypes = {
  name: PropTypes.string.isRequired
}

export default LoadingIcon;