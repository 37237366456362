import CTable from 'components/table/ctable';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import Badge from 'themes/overrides/Badge';
import { Download } from 'react-feather';
import LoadingIcon from 'components/loading/loading-icon';
import { JwtTokenContext, ShopContext } from 'context/context';

registerLocale('es', es);

dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const Order = () => {
    const [jwtToken, setJwtValue, rol] = useContext(JwtTokenContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        reset,
        trigger,
        formState,
        formState: { errors, isDirty, dirtyFields },
    } = useForm();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [minStartDate, setMinStartDate] = useState(new Date('2023-07-31'));
    const [maxStartDate, setMaxStartDate] = useState(new Date());
    const [minEndDate, setMinEndDate] = useState(new Date('2023-07-31'));
    const [maxEndDate, setMaxEndDate] = useState(new Date());
    let columns = ['email', 'order_number', 'order_status', 'order_total', 'order_net_total', 'currency', 'order_total_eur', 'commission_eur', 'country', 'platform', 'app_version', 'updated_date', 'created_date'];
    //si el rol es DEVELOPER quitamos la columna commission_eur
    if (rol == 'DEVELOPER') {
        columns = ['email', 'order_number', 'order_status', 'order_total', 'order_net_total', 'currency', 'order_total_eur', 'country', 'platform', 'app_version', 'updated_date', 'created_date']
    }
    const [data, setData] = useState({ data: null, total: 0, columns: columns, sort: { columnIndex: null, order: 0 }, pagination: {'active': 1, 'pageSize': 0, 'numPages': 0}, actions: rol == 'ADMIN' ? {'update': '/orders/update/{id}', 'delete': null } : null });
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') ?? 1);    
    const {shop} = useContext(ShopContext);  
    //se puede hacer con hooks tambien
    //const { field } = useController({ control, name: 'SearchForm.start_date', defaultValue: null });
    const [childShops, setChildShops] = useState(null);

    const getInitialValues = (async () => {
        try {
            let url = `${environment.apiUrl}/getChildShops?`;

            let params = new URLSearchParams();

            params.append('domain', shop);

            url += params.toString();

            //nos traemos los datos iniciales
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
            setChildShops(res);

        } catch(e) {
            console.error(e);
        }
    });

    useEffect(() => {
        getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shop]);

    useEffect(() => {
        (async() => {
            if (shop && !empty(childShops)) {
                let url = `${environment.apiUrl}/getOrders?`;
                let sParams = { ...Object.fromEntries(searchParams.entries()) };
                
                if (empty(sParams?.page)) {
                    sParams.page = 1;
                }
                
                let params = new URLSearchParams(sParams);

                params.append('domain', shop);

                url += params.toString();

                //reseteamos los valores del formulario
                resetFormFields();

                //colocamos los valores iniciales del input
                params.forEach((value, key) => {
                    if (key != 'order_status' && key != 'webshop_id') {
                        setValue('SearchForm.' + key, value);
                    } else if (key == 'webshop_id') {
                        let vObject = [];
                        let label = childShops?.find((e) => e.value == value)?.label ?? '';
                        if (!empty(value) && !empty(label)) {
                            vObject = { value: value, label: label };   
                        }
                        setValue('SearchForm.' + key, vObject);
                    } else {
                        let orderStatus = [];
                        if (!empty(value)) {
                            if (Array.isArray(orderStatus)) {
                                orderStatus = value.split(',').map((v, k) => {
                                    return { value: v, label: v}
                                });
                            } else {
                                orderStatus = [{ value: value, label: value}];
                            }                        
                        }
                        setValue('SearchForm.' + key, orderStatus);
                    }
                });

                const response = await fetch(url, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Content-Type': 'application/json',
                        'Timezone': dayjs.tz.guess(),
                    },
                });

                const res = await response.json();

                if (!empty(res)) {
                    const page = res?.page;
                    const pageSize = res?.pageSize;
                    const numPages = res?.numPages;
                    const items = res?.items;
                    const sortColumn = res?.sortColumn;
                    const sortOrder = res?.sortOrder; 
                    const total = res?.total; 

                    const params = { ...Object.fromEntries(searchParams.entries()) };

                    if (page != 1) {
                        params.page = page;
                    }

					//si modificamos URLSearchParams tenemos que evitar cambiar tambien setSearchParams
                    //setSearchParams(params, { replace: true });
                    setData({ ...data, data: items, total: total, sort: { columnIndex: sortColumn, order: sortOrder }, pagination: {'active': page, 'pageSize': pageSize, 'numPages': numPages} });
                }
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, shop, childShops]);

    const onSubmit = async ({SearchForm: data}) => {
        let orderStatus = null;
        if (!empty(data?.order_status)) {
            if (Array.isArray(data?.order_status)) {
                orderStatus = data.order_status.map((data) => {
                    return data.value;
                });
            } else {
                orderStatus = [data?.order_status?.value];
            }
        }
        //reseteamos la pagina al buscar
        setCurrentPage(1);
        setSearchParams({ page: 1, search: data?.search ?? '', order_status: orderStatus ? orderStatus.join(',') : '', webshop_id: data?.webshop_id?.value ?? '', platform: data?.platform ?? '', start_date: data?.start_date ?? '', end_date: data?.end_date ?? '' }, { replace: false });
    }

    const resetFormFields = () => {
        const formName = 'SearchForm';
        const data = {};
        const keys = Object.keys(getValues(formName));
        for (const key of keys) {
            data[`${formName}.${key}`] = '';
        }
        //reseteamos tambien los estados
        setStartDate('');
        setMinEndDate('');
        setEndDate('');
        reset(data, { keepDirty: true });
    }

    const resetAllFields = () => {
        resetFormFields();
        return handleSubmit(onSubmit)();
    }

    const downloadExcel = () => {
        setLoadingDownload(true);
        const formName = 'SearchForm';
        const data = {};
        const keys = Object.keys(getValues(formName));
        for (const key of keys) {
            data[`${formName}.${key}`] = '';
        }
        
        return handleSubmit(onSubmitDownload)();
    }

    const onSubmitDownload = async ({SearchForm: data}) => {
        let url = `${environment.apiUrl}/getExcelOrders?`;
        //quitamos valores undefined
        for (const key in data) {
            data[key] = data[key] ?? '';
        }

        let orderStatus = null;
        if (!empty(data?.order_status)) {
            if (Array.isArray(data?.order_status)) {
                orderStatus = data.order_status.map((data) => {
                    return data.value;
                });
            } else {
                orderStatus = [data?.order_status?.value];
            }
        }

        let params = new URLSearchParams({ ...data, order_status: orderStatus ? orderStatus.join(',') : '' });

        url += params.toString();

        const response = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                'Timezone': dayjs.tz.guess(),
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });

        const blob = await response.blob();

        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);

        setLoadingDownload(false);
    }

    return (
        <>
            <div className="container-fluid p-0">

                <div className="mb-3">
                    <h1 className="h3 d-inline align-middle">
                        <strong>{t('orders')}</strong>
                    </h1>
                </div>
            
                <div className="row">
                    <div className="col-12 d-flex">
                        <div className="w-100">
                            <div className="row">
                                <div className="card">
                                    <div className="card-body">
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-xl-2">
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="validationCustom01">
                                                    <Form.Label>{t('search')}</Form.Label>
                                                    <Form.Control
                                                        {...register('SearchForm.search', {})} 
                                                        type="text" 
                                                        placeholder={t('search')}
                                                        onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                                                        onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                    />
                                                    { errors.SearchForm?.search && <Badge bg="danger" className="mt-2">{errors.SearchForm.search?.message}</Badge> }
                                                </Form.Group>
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="SearchForm.webshop_id">
                                                    <Form.Label>{t('webshop_id')}</Form.Label>
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'SearchForm.webshop_id'}
                                                        render={({ field }) => (
                                                            <Select
                                                                // inputClassName={'form-control'}
                                                                // height={34}
                                                                // className="react-select-container"
                                                                // classNamePrefix="react-select"
                                                                {...register('SearchForm.webshop_id', {})}
                                                                placeholder={t('select_child_shop')}
                                                                onChange={(value) => { 
                                                                    field.onChange(value); 
                                                                    //trigger('SearchForm.webshop_id'); 
                                                                }} 
                                                                value={field.value}
                                                                styles={{
                                                                    control: base => {
                                                                        let css = {
                                                                            ...base,
                                                                            height: (Array.isArray(field.value) && field.value.length > 0) ? base?.height : 34,
                                                                            minHeight: 34,
                                                                        }; 
                                                                        return css;
                                                                    }
                                                                }}
                                                                
                                                                isClearable
                                                                closeMenuOnSelect={true}
                                                                // defaultValue={selectedOption}
                                                                // onChange={setSelectedOption}
                                                                options={childShops}
                                                            />
                                                        )} 
                                                    />
                                                
                                                    { errors.SearchForm?.webshop_id && <Badge bg="danger" className="mt-2">{errors.SearchForm.webshop_id?.message}</Badge> }
                                                </Form.Group>
                                                <Form.Group as={Col} xl="4" className='mb-2 mb-xl-0' controlId="validationCustom02">
                                                    <Form.Label>{t('order_status')}</Form.Label>
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'SearchForm.order_status'}
                                                        render={({ field }) => (
                                                            <Select
                                                                // inputClassName={'form-control'}
                                                                // height={34}
                                                                // className="react-select-container"
                                                                // classNamePrefix="react-select"
                                                                placeholder={t('select_status')}
                                                                onChange={(value) => { 
                                                                    field.onChange(value); 
                                                                }} 
                                                                value={field.value}
                                                                styles={{
                                                                    control: base => {
                                                                        let css = {
                                                                            ...base,
                                                                            height: (Array.isArray(field.value) && field.value.length > 0) ? base?.height : 34,
                                                                            minHeight: 34,
                                                                        }; 
                                                                        return css;
                                                                    }
                                                                }}
                                                                isMulti
                                                                isClearable
                                                                closeMenuOnSelect={false}
                                                                // defaultValue={selectedOption}
                                                                // onChange={setSelectedOption}
                                                                options={environment.orderStatus}
                                                            />
                                                        )} 
                                                    />
                                            
                                                    { errors.SearchForm?.order_status && <Badge bg="danger" className="mt-2">{errors.SearchForm.order_status?.message}</Badge> }
                                                </Form.Group>
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="validationCustom03">
                                                    <Form.Label>{t('platform')}</Form.Label>
                                                    <Form.Select aria-label={t('select')}
                                                        {...register('SearchForm.platform', {})}
                                                    >
                                                        <option value=''>{t('select_platform')}</option>
                                                        <option value="android">{t('android')}</option>
                                                        <option value="ios">{t('ios')}</option>
                                                    </Form.Select>
                                                    { errors.SearchForm?.platform && <Badge bg="danger" className="mt-2">{errors.SearchForm.platform?.message}</Badge> }
                                                </Form.Group>
                                                <Form.Group as={Col} xl="2" className='mb-2 mb-xl-0' controlId="validationCustom04">
                                                    <Form.Label>{t('start_date')}</Form.Label>
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'SearchForm.start_date'}
                                                        render={({ field }) => (
                                                            <DatePicker 
                                                                customInput={<Form.Control
                                                                type="text"
                                                            />} 
                                                                selected={startDate} 
                                                                onChange={(date) => { 
                                                                    setStartDate(date);
                                                                    setMinEndDate(date);
                                                                    if (date) {
                                                                        field.onChange(dayjs(date).format('DD-MM-YYYY'));
                                                                    } else {
                                                                       field.onChange(''); 
                                                                    }
                                                                }} 
                                                                value={field.value}
                                                                dateFormat="dd-MM-yyyy" 
                                                                locale="es"
                                                                minDate={minStartDate}
                                                                maxDate={maxStartDate}
                                                                placeholderText={t('start_date')}
                                                                closeOnScroll={true}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    { errors.SearchForm?.start_date && <Badge bg="danger" className="mt-2">{errors.SearchForm.start_date?.message}</Badge> }
                                                </Form.Group>
                                            </Row>
                                            
                                            <Row className="mb-xl-2 d-flex">
                                    
                                                <Form.Group as={Col} xl="2" className='mb-xl-0' controlId="validationCustom05">
                                                    <Form.Label>{t('end_date')}</Form.Label>
                                                    <Controller 
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        name={'SearchForm.end_date'}
                                                        render={({ field }) => (
                                                            <DatePicker customInput={<Form.Control
                                                                type="text"
                                                            />} selected={endDate} 
                                                                onChange={(date) => { 
                                                                    setEndDate(date);
                                                                    if (date) {
                                                                        field.onChange(dayjs(date).format('DD-MM-YYYY'));
                                                                    } else {
                                                                       field.onChange(''); 
                                                                    }
                                                                }} 
                                                                value={field.value}
                                                                dateFormat="dd-MM-yyyy" 
                                                                locale="es"
                                                                minDate={minEndDate}
                                                                maxDate={maxEndDate}
                                                                placeholderText={t('end_date')}
                                                                closeOnScroll={true}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    { errors.SearchForm?.end_date && <Badge bg="danger" className="mt-2">{errors.SearchForm.end_date?.message}</Badge> }
                                                </Form.Group>
                                            
                                                <Form.Group as={Col} xl="2" className='align-items-end d-flex mb-2 mb-xl-0' controlId="validationCustom06">
                                                    <Button type="submit" className='w-100' disabled={loading || !isDirty}><i className="bi bi-search"></i>&nbsp;{t('search')}</Button>
                                                </Form.Group>
                                                <Form.Group as={Col} xl="2" className='align-items-end d-flex mb-2 mb-xl-0' controlId="validationCustom06">
                                                    <Button type="button" className='w-100 btn-danger' onClick={() => resetAllFields()}><i className="bi bi-x-circle"></i>&nbsp;{t('reset')}</Button>
                                                </Form.Group>
                                                <Form.Group as={Col} xl="6" className='align-items-end d-flex justify-content-end' controlId="validationCustom06">
                                                    <Button type="button" className='btn-success d-flex' onClick={() => downloadExcel()} disabled={loadingDownload}>
                                                        { 
                                                            loadingDownload 
                                                            ? 
                                                                <LoadingIcon style={{position: 'inherit', right: 'auto'}} name={'three-dots'}/> 
                                                            : 
                                                                <>
                                                                    <Download className="align-middle" style={{ width: '20px', height: '20px'}}/><span>&nbsp;</span>
                                                                    { t('download_excel') }
                                                                </>
                                                        }
                                                    </Button>
                                                </Form.Group>
                                            </Row>
                                            
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 d-flex">
                        <div className="w-100">
                            <div className="row">
                                <div className="card">

                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <CTable data={data} setCurrentPage={setCurrentPage} deleteAction={rol == 'ADMIN' ? '/deleteOrder' : null}></CTable>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default Order;