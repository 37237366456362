/*eslint no-undef: "off"*/
import React from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { useState } from 'react';
import LoadingIcon from 'components/loading/loading-icon';
import { useLazyLoadExtScript } from 'hooks/useLazyLoadExtScript';
import config from 'config/config';
import { environment } from 'environments/environment';
import { toast } from 'react-toastify';

const RecoveryPassword = () => {

    useLazyLoadExtScript(config.recaptchaSRC);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        getValues,
        formState,
        reset,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });

    const onSubmit = async ({RecoveryForm: data}) => {
        // async request which may result error
        try {   
            setLoading(true);

            let captchaToken = await window.grecaptcha.execute(config.recaptchaSiteKey, { action: 'recovery' });
            let url = `${environment.apiUrl}/recoveryPassword?`; 
            
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    captcha: captchaToken,
                })
            });
            
            const res = await response.json();

            if (res) {
                toast.success(t('link_sent_successfully'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                //reseteamos el formulario
                reset();
            } else {
                toast.error(t('link_sent_error'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false);
    }

    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">

                            <div className="text-center mt-4">
                                <h1 className="h2">{t('recovery_password')}</h1>
                                <p className="lead">
                                    {t('enter_your_email')}
                                </p>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-3">

                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3" controlId="RecoveryForm.email">
                                                <Form.Label>{t('email')}</Form.Label>
                                                <Form.Control type="email" {...register('RecoveryForm.email', { required: t('required_field', { field: t('email') }) })} placeholder={t('email')} />
                                                { errors.RecoveryForm?.email && <Badge bg="danger" className="mt-2">{errors.RecoveryForm.email?.message}</Badge> }
                                            </Form.Group>
                                            <Form.Group className="mt-3 d-grid gap-2">
                                                <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-send"></i>&nbsp;{t('send')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                            </Form.Group>
                                        </Form>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mb-3">
                                { t('do_you_want_to_access') } <Link to="/login">{t('sign_in')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default RecoveryPassword;
