import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { environment } from 'environments/environment';

export const fetchCreateJwt = createAsyncThunk(
  'modalCreateJwt/createJwt',
  async (data, { getState }) => {
    const state = getState();
    const response = await fetch(`${environment.apiUrl + data.url}`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",
        },
        //body: JSON.stringify({ deleteId: state?.modalConfirmation?.deleteId}), // body data type must match "Content-Type" header
    });

    return await response.json();
  },
);

export const modalCreateJwtSlice = createSlice({
  name: 'modalCreateJwt',
  initialState: {
    show: false,
    title: null,
    message: null,
    url: null,
  },
  reducers: {
    show: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes.
        // Also, no return statement is required from these functions.
        state.show = true;
        state.title = action.payload.title;
        state.message = action.payload.message;
    },
    hide: (state, action) => {
        state.show = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateJwt.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(fetchCreateJwt.fulfilled, (state, action) => {
        //state.status = 'succeeded';
        //state.token = action.payload?.token;
      })
      .addCase(fetchCreateJwt.rejected, (state, action) => {
        //state.status = 'failed';
        //state.error = action.error.message;
      });
  },
})

// Action creators are generated for each case reducer function
export const { show, hide, createJwt } = modalCreateJwtSlice.actions;

export default modalCreateJwtSlice.reducer;