import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { environment } from 'environments/environment';

export const fetchCreateIp = createAsyncThunk(
  'modalCreateIp/createIp',
  async (data, { getState }) => {
    const state = getState();
    const response = await fetch(`${environment.apiUrl + data.url}`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ ip_address: data?.ip_address }), // body data type must match "Content-Type" header
    });

    return await response.json();
  },
);

export const modalCreateIpSlice = createSlice({
  name: 'modalCreateIp',
  initialState: {
    show: false,
    title: null,
    message: null,
    url: null,
  },
  reducers: {
    showModalCreateIp: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes.
        // Also, no return statement is required from these functions.
        state.show = true;
        state.title = action.payload.title;
        state.message = action.payload.message;
    },
    hideModalCreateIp: (state, action) => {
        state.show = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateIp.pending, (state) => {
        //state.status = 'loading';
      })
      .addCase(fetchCreateIp.fulfilled, (state, action) => {
        //state.status = 'succeeded';
        //state.token = action.payload?.token;
      })
      .addCase(fetchCreateIp.rejected, (state, action) => {
        //state.status = 'failed';
        //state.error = action.error.message;
      });
  },
})

// Action creators are generated for each case reducer function
export const { showModalCreateIp, hideModalCreateIp } = modalCreateIpSlice.actions;

export default modalCreateIpSlice.reducer;