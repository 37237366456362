import React, { createContext, useCallback, useEffect } from 'react';

const JwtTokenContext = createContext('jwtToken');

const ApiJwtContext = createContext(null);

const ShopContext = createContext(null);

const ModalConfirmContext = createContext(null);

export {JwtTokenContext, ApiJwtContext, ShopContext, ModalConfirmContext};