import LoadingPage from 'components/loading/loading-page';
import { JwtTokenContext } from 'context/context';
import { empty } from 'helper/helper';
import { React, useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
const Protected = ({ requiresLogin, children }) => {
    const [jwtToken, setJwtValue, rol, email, firstRequest] = useContext(JwtTokenContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [pass, setPass] = useState(false);
    //const isLoggedIn = useSelector((state) => state.auth);

    useEffect(() => {
        if (!empty(rol)) {
            if (requiresLogin && empty(jwtToken)) {
                navigate('/login');
            } else if (requiresLogin && !empty(jwtToken)) {
                if ((pathname == '' || pathname == '/') && rol == 'DEVELOPER') {
                    navigate('/notifications');
                    setPass(true);
                } else {
                    setPass(true);
                }
            } else if (!requiresLogin) {
                setPass(true);
            }
        } else {
            if (requiresLogin && firstRequest) {
                navigate('/login');
            } else {
                setPass(true);
            }
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiresLogin, jwtToken, rol]);

  return (
    <>
        { pass ? children : <LoadingPage name={'bars'}/>}
    </>);
}

export default Protected;