import React, { useContext, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hideModalJwt, fetchShowJwt } from 'redux/slices/modal/modalShowJwtSlice';
import { useForm } from 'react-hook-form';
import { Badge, Form } from 'react-bootstrap';
import LoadingIcon from 'components/loading/loading-icon';
import * as dayjs from 'dayjs';
import { ApiJwtContext } from 'context/context';

function ModalShowJwt({ url }) {
    const [jwtData, setJwtData] = useContext(ApiJwtContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        clearErrors,
        setError,
        getValues,
        setValue,
        formState,
        reset,
        formState: { errors, isDirty, dirtyFields },
    } = useForm();
    const formRef = useRef();
 
    const handleClose = () => {
        reset();
        dispatch(hideModalJwt());
    };

    const show = useSelector((state) => {
        return state.modalShowJwt.show;
    });

    const title = useSelector((state) => state.modalShowJwt.title);
    const message = useSelector((state) => state.modalShowJwt.message);

    const onSubmit = async ({ PasswordForm: data }) => {
        const response = await dispatch(fetchShowJwt({ url: url, password: data?.password }));
        if (response.payload?.success === true) {
            reset();
            dispatch(hideModalJwt());
            toast.success(t('success_show_jwt'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            
            if (response?.payload?.data) {
                setJwtData({ ...jwtData, data: response?.payload?.data, hiddenJwt: false });
            }

        } else {
            // toast.error(t('error_show_jwt'), {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });

            clearErrors();
            //reseteamos para bloquear el boton de envío
            reset(null, { keepDirty: false });
            setError(`PasswordForm.password`, { type: 'custom', message: t('password_is_not_valid') });
        }
    }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{message}</p>
            <Form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <Form.Group className="mb-3" controlId="PasswordForm.password">
                    <Form.Label className='required'>{t('password')}</Form.Label>
                    <Form.Control type="password" 
                    {...register('PasswordForm.password', { required: t('required_field', { field: t('password') }) })} 
                    placeholder={t('password')} 
                    onBlur={(e) => setValue(e.target.name, e.target.value.trim())}
                    onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                    />
                    { errors.PasswordForm?.password && <Badge bg="danger" className="mt-2">{errors.PasswordForm?.password.message}</Badge> }
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            { t('close') }
          </Button>
          <Button onClick={(e) => formRef.current.requestSubmit() } variant="success" disabled={loading || !isDirty}><i className="bi bi-send"></i>&nbsp;{t('send')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalShowJwt;