import LoadingIcon from 'components/loading/loading-icon';
import { environment } from 'environments/environment';
import { empty } from 'helper/helper';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Badge, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import DatePicker, { registerLocale } from "react-datepicker";
import { Editor } from '@tinymce/tinymce-react';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { ModalConfirmContext, ShopContext } from 'context/context';
import Select from 'react-select';
import ModalNotificationConfirm from 'components/modal/modal-notification-confirm';

registerLocale('es', es);

dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const NotificationCreate = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        control,
        trigger,
        watch,
        reset,
        getValues,
        clearErrors,
        setError,
        formState,
        setValue,
        formState: { errors, isDirty, dirtyFields },
    } = useForm({
        mode: 'all'
    });

    const editorTitleRef = useRef(null);
    const editorBodyRef = useRef(null);
    const {shop} = useContext(ShopContext);  
    const [childShops, setChildShops] = useState(null);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShowModalConfirm(true);
    //     }, 2000);
    // }, [])

    const getInitialValues = (async () => {
        try {
            let url = `${environment.apiUrl}/getChildShops?`;

            let params = new URLSearchParams();

            params.append('domain', shop);

            url += params.toString();

            //nos traemos los datos iniciales
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();

            setChildShops(res);

        } catch(e) {
            console.error(e);
        }
    });

    useEffect(() => {
        getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shop]);

    const preOnSubmit = ({NotificationCreateForm: data}) => {
        // Comprobamos si la notificacion es programada o directa
        setConfirmMessage(t('send_confirm_message', { type: !empty(data?.pre_scheduled) ? t('scheduled_send_type') : t('immediate_send_type'), deviceUid: !empty(data?.device_uid) ? data?.device_uid : t('empty') }));
        setShowModalConfirm(true);
    }
    
    const onSubmit = async () => {
        // async request which may result error
        try {

            // Ocultamos el modal de confirmacion
            setShowModalConfirm(false);

            setLoading(true);   

            // Extraemos los valores del formulario
            const data = getValues('NotificationCreateForm');

            //para evitar problemas con los campos de tipo Editor vamos a tomar el campo directamente en texto plano
            if (data?.title && editorTitleRef?.current) {
                data.title = editorTitleRef.current.getContent({ format: 'text' }).trim();
            }

            if (data?.body && editorBodyRef?.current) {
                data.body = editorBodyRef.current.getContent({ format: 'text' }).trim();
            }

            if (!empty(data?.pre_scheduled)) {
                data.scheduled = dayjs(data.pre_scheduled, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:00');
            } else {
                data.scheduled = '';
            }

            const response = await fetch(`${environment.apiUrl}/sendNotification`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                    'Timezone': dayjs.tz.guess(),
                },
                body: JSON.stringify({ 
                    ...data, 
                    domain: shop,
                    webshop_id: data?.webshop_id?.value,
                }), // body data type must match "Content-Type" header
            });
            const res = await response.json();

            if (res.success == true && res.data?.successMessages !== null) {
                toast.success(<>{ t('success_sent') } <br/>{ t('success_sent_messages', { successMessages: res.data?.successMessages}) }<br/>{ t('error_sent_messages', { errorMessages: res.data?.errorMessages })}</>, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                if (!data.keep_form) {
                    //reseteamos el formulario
                    resetAllFields();
                }
            } else if (res.success == true && res.data?.successMessages === null) {
                toast.success(t('success_scheduled'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                
                if (!data.keep_form) {
                    //reseteamos el formulario
                    resetAllFields();
                }
            } else {
                clearErrors();
                //recorremos los errores
                for (const error of res.data) {
                    //tenemos que rellenar los errores
                    for (const e in error.constraints) {
                        setError(`NotificationCreateForm.${error.property}`, { type: 'custom', message: error.constraints[e] })      
                    }
                }
            }

        } catch (e) {
            // handle your error
        }
        setLoading(false); 
    }

    const [startDate, setStartDate] = useState('');
    const [minStartDate, setMinStartDate] = useState(new Date());
    const [maxStartDate, setMaxStartDate] = useState(dayjs().add(30, 'day').toDate());
    
    let notificationType = watch('NotificationCreateForm.notification_type');

    const resetAllFields = () => {
        const formName = 'NotificationCreateForm';
        let data = {};
        const keys = Object.keys(getValues(formName));
        for (const key of keys) {
            data[`${formName}.${key}`] = '';
        }

        data = { ...data, "NotificationCreateForm.keep_form": false, "NotificationCreateForm.background_only": false };

        reset(data, { keepDirty: true });
    }

    return (
        <>
            <div className="container-fluid p-0">

                <div className="mb-3">
                    <h1 className="h3 d-inline align-middle">
                        <strong>{t('create_notification')}</strong>
                    </h1>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-6 d-flex">
                        <div className="w-100">
                     
                            <div className="card">

                                <div className="card-body">
                                    <div className="m-sm-3">

                                        <Alert key={'info'} variant={'info'}>
                                            <i className="bi bi-info-square-fill"></i> { t('warning_message_create_notification') }
                                        </Alert>

                                        <Form onSubmit={handleSubmit(preOnSubmit)}>
                                            <Form.Group className="mb-3" controlId="NotificationCreateForm.pre_scheduled">
                                                <Form.Label>{t('scheduled')}</Form.Label>
                                                <Controller 
                                                    control={control}
                                                    register={register}
                                                    setValue={setValue}
                                                    name={'NotificationCreateForm.pre_scheduled'}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {
                                                                ...register("NotificationCreateForm.pre_scheduled", {
                                                                    validate: { 
                                                                        validate: (value) => { 
                                                                            if (!empty(value)) {
                                                                                //tenemos que comprobar que la fecha sea posterior a la actual
                                                                                const before = dayjs().isBefore(dayjs(value, 'DD-MM-YYYY HH:mm'));
                                                                                if (!before) {
                                                                                    return t('check_date');
                                                                                }
                                                                            }
                                                                            return true;
                                                                        }
                                                                    
                                                                    }
                                                                })
                                                            }
                                                            customInput={<Form.Control type="text" />} 
                                                            selected={startDate}
                                                            onChange={(date) => { 
                                                                setStartDate(date);
                                                                if (date != '' && date != null) {
                                                                    field.onChange(dayjs(date).format('DD-MM-YYYY HH:mm'));
                                                                } else {
                                                                    field.onChange('');
                                                                }  
                                                            }}
                                                            timeInputLabel={t('time')}
                                                            dateFormat="dd-MM-yyyy HH:mm"
                                                            value={field.value}
                                                            closeOnScroll={true}
                                                            placeholderText={t('scheduled')}
                                                            onFocus={e => {
                                                                e.target.blur()
                                                                // Esto es vital para que no resetee el campo en caso de que no solo abra el calendario y no cambie la fecha
                                                                field.onChange(field.value)
                                                            }}
                                                            minDate={minStartDate}
                                                            maxDate={maxStartDate}
                                                            locale="es"
                                                            isClearable
                                                            showTimeInput
                                                        />
                                                    )}
                                                />
                                                { errors.NotificationCreateForm?.pre_scheduled && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.pre_scheduled?.message}</Badge> }
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="NotificationCreateForm.notification_type">
                                                <Form.Label className='required'>{t('notification_type')}</Form.Label>
                                                <Form.Select aria-label={t('select_type')} {...register('NotificationCreateForm.notification_type', { required: t('required_field', { field: t('notification_type') }) })} placeholder={t('notification_type')} >
                                                    <option value="">{t('select_type')}</option>
                                                    <option value="firebase">{t('firebase')}</option>
                                                    <option value="direct">{t('direct')}</option>
                                                    <option value="cleaning">{t('cleaning')}</option>
                                                </Form.Select>
                                                { errors.NotificationCreateForm?.notification_type && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm?.notification_type.message}</Badge> }
                                            </Form.Group>
                                            {
                                                notificationType != 'cleaning'
                                                ?   
                                                    <React.Fragment>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.email">
                                                            <Form.Label>{t('email')}</Form.Label>
                                                            <Form.Control type="text" 
                                                                {...register('NotificationCreateForm.email', { 
                                                                    validate: { 
                                                                        required: (value) => {
                                                                            //t('required_field', { field: t('email') })
                                                                            if (empty(value) && empty(getValues('NotificationCreateForm.webshop_id')?.value) && empty(getValues('NotificationCreateForm.device_uid').trim())) {
                                                                                return t('required_field_conditional', { field1: t('email'), field2: t('webshop_id'), field3: t('device_uid') });
                                                                            } else {
                                                                                return true;
                                                                            }
                                                                        }
                                                                    }
                                                                })} 
                                                            placeholder={t('email')} 
                                                            onChange={(e) => { setValue(e.target.name, e.target.value.trim()); trigger('NotificationCreateForm.email'); trigger('NotificationCreateForm.webshop_id'); trigger('NotificationCreateForm.device_uid'); }}
                                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                            />
                                                            { errors.NotificationCreateForm?.email && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.email?.message}</Badge> }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.webshop_id">
                                                            <Form.Label>{t('webshop_id')}</Form.Label>
                                                            <Controller 
                                                                control={control}
                                                                register={register}
                                                                setValue={setValue}
                                                                name={'NotificationCreateForm.webshop_id'}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        // inputClassName={'form-control'}
                                                                        // height={34}
                                                                        // className="react-select-container"
                                                                        // classNamePrefix="react-select"
                                                                        {...register('NotificationCreateForm.webshop_id', { 
                                                                            validate: { 
                                                                                required: (value) => {
                                                                                    //t('required_field', { field: t('email') })
                                                                                    if (empty(value) && empty(getValues('NotificationCreateForm.email').trim()) && empty(getValues('NotificationCreateForm.device_uid').trim())) {
                                                                                        return t('required_field_conditional', { field1: t('email'), field2: t('webshop_id'), field3: t('device_uid') });
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        })}
                                                                        placeholder={t('select_child_shop')}
                                                                        onChange={(value) => { 
                                                                            field.onChange(value); 
                                                                            trigger('NotificationCreateForm.email'); 
                                                                            trigger('NotificationCreateForm.webshop_id'); 
                                                                            trigger('NotificationCreateForm.device_uid');
                                                                        }} 
                                                                        value={field.value}
                                                                        styles={{
                                                                            control: base => {
                                                                                let css = {
                                                                                    ...base,
                                                                                    height: (Array.isArray(field.value) && field.value.length > 0) ? base?.height : 34,
                                                                                    minHeight: 34,
                                                                                }; 
                                                                                return css;
                                                                            }
                                                                        }}
                                                                        
                                                                        isClearable
                                                                        closeMenuOnSelect={true}
                                                                        // defaultValue={selectedOption}
                                                                        // onChange={setSelectedOption}
                                                                        options={childShops}
                                                                    />
                                                                )} 
                                                            />
                                                        
                                                            { errors.NotificationCreateForm?.webshop_id && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.webshop_id?.message}</Badge> }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.device_uid">
                                                            <Form.Label>{t('device_uid')}</Form.Label>
                                                            <Form.Control type="text" 
                                                                {...register('NotificationCreateForm.device_uid', { 
                                                                    validate: { 
                                                                        required: (value) => {
                                                                            //t('required_field', { field: t('device_uid') })
                                                                            if (empty(value) && empty(getValues('NotificationCreateForm.email').trim()) && empty(getValues('NotificationCreateForm.webshop_id')?.value)) {
                                                                                return t('required_field_conditional', { field1: t('email'), field2: t('webshop_id'), field3: t('device_uid') });
                                                                            } else {
                                                                                return true;
                                                                            }
                                                                        }
                                                                    }
                                                                })} 
                                                            placeholder={t('device_uid')}
                                                            onChange={(e) => { setValue(e.target.name, e.target.value.trim()); trigger('NotificationCreateForm.email'); trigger('NotificationCreateForm.webshop_id'); trigger('NotificationCreateForm.device_uid'); }}
                                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                            />
                                                            { errors.NotificationCreateForm?.device_uid && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.device_uid?.message}</Badge> }
                                                        </Form.Group>
                                                    </React.Fragment>
                                                : 
                                                    <React.Fragment>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.webshop_id">
                                                            <Form.Label>{t('webshop_id')}</Form.Label>
                                                            <Controller 
                                                                control={control}
                                                                register={register}
                                                                setValue={setValue}
                                                                name={'NotificationCreateForm.webshop_id'}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        // inputClassName={'form-control'}
                                                                        // height={34}
                                                                        // className="react-select-container"
                                                                        // classNamePrefix="react-select"
                                                                        {...register('NotificationCreateForm.webshop_id', { 
                                                                            validate: {}
                                                                        })}
                                                                        placeholder={t('select_child_shop')}
                                                                        onChange={(value) => { 
                                                                            field.onChange(value); 
                                                                        }} 
                                                                        value={field.value}
                                                                        styles={{
                                                                            control: base => {
                                                                                let css = {
                                                                                    ...base,
                                                                                    height: (Array.isArray(field.value) && field.value.length > 0) ? base?.height : 34,
                                                                                    minHeight: 34,
                                                                                }; 
                                                                                return css;
                                                                            }
                                                                        }}
                                                                        isClearable
                                                                        closeMenuOnSelect={true}
                                                                        // defaultValue={selectedOption}
                                                                        // onChange={setSelectedOption}
                                                                        options={childShops}
                                                                    />
                                                                )} 
                                                            />
                                                            { errors.NotificationCreateForm?.webshop_id && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.webshop_id?.message}</Badge> }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.device_uid">
                                                            <Form.Label>{t('device_uid')}</Form.Label>
                                                            <Form.Control type="text" 
                                                                {...register('NotificationCreateForm.device_uid', { 
                                                                    validate: { }
                                                                })} 
                                                            placeholder={t('device_uid')}
                                                            onChange={(e) => { setValue(e.target.name, e.target.value.trim()); }}
                                                            onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                            />
                                                            { errors.NotificationCreateForm?.device_uid && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.device_uid?.message}</Badge> }
                                                        </Form.Group>
                                                    </React.Fragment>
                                            }
                                            {/* <Form.Group className="mb-3" controlId="NotificationCreateForm.webshop_id">
                                                <Form.Label>{t('webshop_id')}</Form.Label>
                                                <Form.Control type="text" 
                                                    {...register('NotificationCreateForm.webshop_id', { 
                                                        validate: { 
                                                            required: (value) => {
                                                                //t('required_field', { field: t('email') })
                                                                if (empty(value) && empty(getValues('NotificationCreateForm.email').trim()) && empty(getValues('NotificationCreateForm.device_uid').trim())) {
                                                                    return t('required_field_conditional', { field1: t('email'), field2: t('webshop_id'), field3: t('device_uid') });
                                                                } else {
                                                                    return true;
                                                                }
                                                            }
                                                        }
                                                    })}
                                                placeholder={t('webshop_id')}
                                                onChange={(e) => { setValue(e.target.name, e.target.value.trim()); trigger('NotificationCreateForm.email'); trigger('NotificationCreateForm.webshop_id'); trigger('NotificationCreateForm.device_uid'); }}
                                                onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}
                                                />
                                                { errors.NotificationCreateForm?.webshop_id && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.webshop_id?.message}</Badge> }
                                            </Form.Group> */}
                                            {   
                                                notificationType == 'direct' ? 
                                                    <Form.Group className="mb-3" controlId="NotificationCreateForm.channel_id" >
                                                        <Form.Label className='required'>{t('channel_id')}</Form.Label>
                                                        <Form.Select aria-label={t('select_channel')} {...register('NotificationCreateForm.channel_id', { required: t('required_field', { field: t('channel_id') }) })} placeholder={t('channel_id')} >
                                                            <option value="">{t('select_type')}</option>
                                                            <option value="default-bts">{t('default_bts')}</option>
                                                            <option value="coupons-bts">{t('coupons_bts')}</option>
                                                            <option value="important-bts">{t('important_bts')}</option>
                                                        </Form.Select>
                                                        { errors.NotificationCreateForm?.channel_id && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm?.channel_id.message}</Badge> }
                                                    </Form.Group>
                                                : ''
                                            }
                                            {
                                                notificationType != 'cleaning'
                                                ?
                                                    <React.Fragment>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.open_url">
                                                            <Form.Label>{t('open_url')}</Form.Label>
                                                            <Form.Control type="url" pattern="https://.*" {...register('NotificationCreateForm.open_url', { })} placeholder={t('open_url')} onBlur={(e) => setValue(e.target.name, e.target.value.trim())} onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}/>
                                                            { errors.NotificationCreateForm?.open_url && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.open_url?.message}</Badge> }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.image_url">
                                                            <Form.Label>{t('image_url')}</Form.Label>
                                                            <Form.Control type="url" pattern="https://.*(?:\.png|\.jpg){1}" {...register('NotificationCreateForm.image_url', { })} placeholder={t('image_url_placeholder')} onBlur={(e) => setValue(e.target.name, e.target.value.trim())} onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}/>
                                                            { errors.NotificationCreateForm?.image_url && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.image_url?.message}</Badge> }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.title">
                                                            <Form.Label className='required'>{t('title')}</Form.Label>
                                                            {/* <Form.Control type="text" {...register('NotificationCreateForm.title', { required: t('required_field', { field: t('title') }) })} placeholder={t('title')} onBlur={(e) => setValue(e.target.name, e.target.value.trim())} onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}/> */}
                                                            <Controller 
                                                                control={control}
                                                                register={register}
                                                                setValue={(v) => setValue('NotificationCreateForm.title', v, { shouldDirty: true })}
                                                                name={'NotificationCreateForm.title'}
                                                                render={({ field }) => (
                                                                    <Editor
                                                                        {
                                                                            ...register('NotificationCreateForm.title', { required: t('required_field', { field: t('title') }) })
                                                                        }
                                                                        onBlur={(e) => {
                                                                            if (editorTitleRef.current) {
                                                                                let value = editorTitleRef.current.getContent({ format: 'text' }).trim();
                                                                                editorTitleRef.current.setContent(value);
                                                                                field.onChange(value);
                                                                            }
                                                                        }}
                                                                        /*onKeyDown={(e) => {
                                                                            if (editorTitleRef.current && e.key === 'Enter') {
                                                                                editorTitleRef.current.setContent(editorTitleRef.current.getContent({ format: 'text' }).trim());
                                                                            }
                                                                        }}*/
                                                                        onEditorChange={(newValue) => {
                                                                            //field.onChange(newValue);
                                                                            if (editorTitleRef.current) {
                                                                                setValue('NotificationCreateForm.title', editorTitleRef.current.getContent({ format: 'text' }), { shouldDirty: true });
                                                                            }
                                                                            field.onChange(newValue);
                                                                        }}
                                                                        onInit={(evt, editor) => editorTitleRef.current = editor}
                                                                        //https://www.tiny.cloud/docs/tinymce/6/react-pm-host/
                                                                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                                        //https://github.com/WebJamApps/breakpointministries/blob/develop/src/components/BlogEditor/blogEditorUtils.tsx
                                                                        //initialValue={field.value}
                                                                        //tenemos que sustituit initialValue por value para que funcione con react-hook-form
                                                                        value={field.value}
                                                                        //textareaName='NotificationCreateForm.title'
                                                                        init={{
                                                                            height: 200,
                                                                            branding: false,
                                                                            menubar: false,
                                                                            //https://www.tiny.cloud/docs/tinymce/6/statusbar-configuration-options/
                                                                            elementpath: false,
                                                                            help_accessibility: false,
                                                                            // outputFormat: 'text',
                                                                            // allowedEvents: 'onKeyUp,onChange',
                                                                            language: 'es',
                                                                            init_instance_callback: function (editor) {
                                                                                //characters count
                                                                                (editor.getContainer()).querySelector('button.tox-statusbar__wordcount').click();
                                                                                //hide help text
                                                                                //(editor.getContainer()).querySelector('.tox-statusbar__help-text').style.visibility = "hidden";
                                                                            },
                                                                            plugins: [
                                                                                //'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                                //'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                                //'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 
                                                                                'emoticons', 'help', 'wordcount', 'insertdatetime', 'fullscreen', 'preview',
                                                                            ],
                                                                            insertdatetime_dateformat: '%d/%m/%Y',
                                                                            insertdatetime_formats: ['%d/%m/%Y', '%d/%m/%Y %H:%M:%S'],
                                                                            insertdatetime_timeformat: '%H:%M:%S',
                                                                            toolbar: 'undo redo | insertdatetime | emoticons', 
                                                                                /*'undo redo | blocks | ' +
                                                                                'bold italic forecolor | alignleft aligncenter ' +
                                                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                'removeformat | help | emoticons',*/
                                                                            content_style: '',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            { errors.NotificationCreateForm?.title && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.title?.message}</Badge> }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NotificationCreateForm.body">
                                                            <Form.Label className='required'>{t('body')}</Form.Label>
                                                            {/* <Form.Control type="text" {...register('NotificationCreateForm.body', { required: t('required_field', { field: t('body') }) })} placeholder={t('body')} onBlur={(e) => setValue(e.target.name, e.target.value.trim())} onKeyDown={(e) => e.key === 'Enter' ? setValue(e.target.name, e.target.value.trim()) : null}/> */}
                                                            <Controller 
                                                                control={control}
                                                                register={register}
                                                                setValue={(v) => setValue('NotificationCreateForm.body', v, { shouldDirty: true })}
                                                                name={'NotificationCreateForm.body'}
                                                                render={({ field }) => (
                                                                    <Editor
                                                                        {
                                                                            ...register('NotificationCreateForm.body', { required: t('required_field', { field: t('body') }) })
                                                                        }
                                                                        onBlur={(e) => {
                                                                            if (editorBodyRef.current) {
                                                                                let value = editorBodyRef.current.getContent({ format: 'text' }).trim();
                                                                                editorBodyRef.current.setContent(value);
                                                                                field.onChange(value);
                                                                            }
                                                                        }}
                                                                        /*onKeyDown={(e) => {
                                                                            if (editorBodyRef.current && e.key === 'Enter') {
                                                                                editorBodyRef.current.setContent(editorBodyRef.current.getContent({ format: 'text' }).trim());
                                                                            }
                                                                        }}*/
                                                                        onEditorChange={(newValue) => {
                                                                            //field.onChange(newValue);
                                                                            if (editorBodyRef.current) {
                                                                                setValue('NotificationCreateForm.body', editorBodyRef.current.getContent({ format: 'text' }), { shouldDirty: true });
                                                                            }
                                                                            field.onChange(newValue);
                                                                        }}
                                                                        onInit={(evt, editor) => editorBodyRef.current = editor}
                                                                        //https://www.tiny.cloud/docs/tinymce/6/react-pm-host/
                                                                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                                        //https://github.com/WebJamApps/breakpointministries/blob/develop/src/components/BlogEditor/blogEditorUtils.tsx
                                                                        //initialValue={field.value}
                                                                        //tenemos que sustituit initialValue por value para que funcione con react-hook-form
                                                                        value={field.value}
                                                                        //textareaName='NotificationCreateForm.body'
                                                                        init={{
                                                                            height: 200,
                                                                            branding: false,
                                                                            menubar: false,
                                                                            //https://www.tiny.cloud/docs/tinymce/6/statusbar-configuration-options/
                                                                            elementpath: false,
                                                                            help_accessibility: false,
                                                                            // outputFormat: 'text',
                                                                            // allowedEvents: 'onKeyUp,onChange',
                                                                            language: 'es',
                                                                            init_instance_callback: function (editor) {
                                                                                //characters count
                                                                                (editor.getContainer()).querySelector('button.tox-statusbar__wordcount').click();
                                                                                //hide help text
                                                                                //(editor.getContainer()).querySelector('.tox-statusbar__help-text').style.visibility = "hidden";
                                                                            },
                                                                            plugins: [
                                                                                //'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                                //'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                                //'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 
                                                                                'emoticons', 'help', 'wordcount', 'insertdatetime', 'fullscreen', 'preview',
                                                                            ],
                                                                            insertdatetime_dateformat: '%d/%m/%Y',
                                                                            insertdatetime_formats: ['%d/%m/%Y', '%d/%m/%Y %H:%M:%S'],
                                                                            insertdatetime_timeformat: '%H:%M:%S',
                                                                            toolbar: 'undo redo | insertdatetime | emoticons', 
                                                                                /*'undo redo | blocks | ' +
                                                                                'bold italic forecolor | alignleft aligncenter ' +
                                                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                'removeformat | help | emoticons',*/
                                                                            content_style: '',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            { errors.NotificationCreateForm?.body && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm.body?.message}</Badge> }
                                                        </Form.Group>
                                                    </React.Fragment>
                                                : 
                                                    null
                                            }

                                            <Form.Group className="mb-3" controlId="NotificationCreateForm.background_only">
                                                <Form.Label className=''>{t('background_only')}</Form.Label>
                                                <Form.Check type="switch" {...register('NotificationCreateForm.background_only', { required: false })} placeholder={t('background_only')} />
                                                { errors.NotificationCreateForm?.background_only && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm?.background_only.message}</Badge> }
                                            </Form.Group>
                                            
                                            <Form.Group className="mb-3" controlId="NotificationCreateForm.keep_form">
                                                <Form.Label className=''>{t('keep_form_to_submit')}</Form.Label>
                                                <Form.Check type="switch" {...register('NotificationCreateForm.keep_form', { required: false })} placeholder={t('keep_form_to_submit')} />
                                                { errors.NotificationCreateForm?.keep_form && <Badge bg="danger" className="mt-2">{errors.NotificationCreateForm?.keep_form.message}</Badge> }
                                            </Form.Group>

                                            <Form.Group className="mt-3 d-grid gap-2">
                                                <Button className="btn-lg" type="submit" variant="primary" disabled={loading || !isDirty}><i className="bi bi-send"></i>&nbsp;{t('send')}{ loading && <LoadingIcon name={'three-dots'}/> }</Button>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>

                            </div>
                        
                        </div>
                    </div>

                </div>

            </div>

            <ModalConfirmContext.Provider value={{ setShow: setShowModalConfirm, show: showModalConfirm, onSubmit }}>
                <ModalNotificationConfirm title={t('send_confirm_title')} message={confirmMessage}></ModalNotificationConfirm>
            </ModalConfirmContext.Provider>
        </>
    );
}

export default NotificationCreate;