import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { environment } from 'environments/environment';
import jwt_decode from "jwt-decode";

export const fetchLogin = createAsyncThunk(
  'auth/login',
  async (data) => {
    const response = await fetch(`${environment.apiUrl}/login`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    return await response.json();
  },
);

export const checkAuth = createAsyncThunk(
  'auth/check',
  async () => {
    const response = await fetch(`${environment.apiUrl}/check`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
            //"Content-Type": "application/json",
        },
    });
    return await response.json();
  },
);


export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    login: false,
    token: null,
    status: null,
    rol: null,
    email: null,
  },
  reducers: {
    check: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state = { ...state };
    },
    login: (state, action) => {
      state.login = false;
      state.token = action.payload;
      const jwt = jwt_decode(action.payload);
      state.email = jwt.email;
      state.rol = jwt.rol;
    },
    logout: (state, action) => {
      state.login = false;
      state.token = null;
      state.email = null;
      state.rol = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload?.data?.jwt) {
          state.login = true;
          state.token = action.payload?.data?.jwt;
          const jwt = jwt_decode(action.payload?.data?.jwt);
          state.email = jwt.email;
          state.rol = jwt.rol;
        }
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(checkAuth.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.login = true;
        state.token = action.payload?.data?.jwt;
        const jwt = jwt_decode(action.payload?.data?.jwt);
        state.email = jwt.email;
        state.rol = jwt.rol;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
})

// Action creators are generated for each case reducer function
export const { check, login, logout } = authSlice.actions;

export default authSlice.reducer;